export const InputValidatorService = {
    isValidField: (field) => isValidField(field),
    isGroupRequiresNoteInput: (group, isJoint) => isGroupRequiresNoteInput(group, isJoint)
};

const isValidField = (x) => {
    if (x.type.toLowerCase().indexOf('other') === -1 && x.type.toLowerCase() !== 'netgovernmentbenefits')
        return x.amount >= 0;

    if (x.type.toLowerCase().indexOf('other') > -1 || x.type.toLowerCase() === 'netgovernmentbenefits')
        return x.amount > 0 ? (x.note !== undefined && x.note !== null && x.note !== '') : x.amount === 0;

    if (!x.isDisabled && x.isTouched)
        return true;
}

const isGroupRequiresNoteInput = (group, isJoint) => {
    if (group.key === 'bankruptDebtorIncome' && group.total === 0)
        return true;

    if (isJoint && group.key === 'jointBankruptDebtorIncome' && group.total === 0)
        return true;

    if (group.key === 'otherMembersOfTheFamilyUnitIncome' && group.total === 0)
        return true;
}