import React, { useState, useEffect } from 'react';
import Tabs from 'devextreme-react/tabs';
import DatePicker from "react-datepicker";
import moment from 'moment';
import { enGB, de, fr } from 'date-fns/locale';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { Accordion, AccordionItem } from '@szhsin/react-accordion';
import { IEBaseContainerElement, IECategoryContainerElement, ErrorIcon, GroupHeaderElement } from './style';
import { IEFormDataService } from '../utils/ieFormDataService';
import { SummaryCalculator } from '../utils/summaryCalculator';
import { InputValidatorService } from '../utils/validatorService';
import { IEFieldContainer } from "../IEFieldContainer";
import { IESummaryContainer } from "../IESummaryContainer";
import { CustomDatePickerInput } from "../../../_misc/CustomDatePickerInput";
import { IE_FREQUENCY_TYPE } from '../../../../utils/constants';
import { Spinner } from "../../../_misc/Spinner";

import "react-datepicker/dist/react-datepicker.css";
import 'moment-timezone';

export const IEForm65BaseContainer = (props) => {
    const { t, i18n } = useTranslation();
    const [selectedTab, setSelectedTab] = useState(0);
    const [createdDate, setCreatedDate] = useState(props.formRecord.date);
    const [formData, setFormData] = useState(props.formRecord.formData);
    const [tabItems] = useState(IEFormDataService.generateTabs(props.formRecord.formData).map(x => { return { ...x, text: t(`LABELS.${x.dataObject.toUpperCase()}`) } }));
    const [primitiveCategories, setPrimitiveCategories] = useState(<></>);
    const [groupedCategories, setGroupedCategories] = useState(<></>);

    useEffect(() => {
        if (selectedTab < tabItems.length) {
            SummaryCalculator.recalculate(formData);
            setCategories(formData[tabItems[selectedTab].dataObject]);
        }

        props.handleStateChanges({ currentTab: selectedTab, tabsLength: tabItems.length, dataToSave: selectedTab === tabItems.length ? { ...props.formRecord, formData: formData } : null });
    }, [selectedTab]);

    useEffect(() => {
        if (props.onBackwardButtonClicked)
            setSelectedTab((selectedTab) => selectedTab - 1);
    }, [props.onBackwardButtonClicked]);

    useEffect(() => {
        if (props.onForwardButtonClicked) {
            if (!isformDataValid(true)) {
                showWarnMessage();
                return;
            }
            setSelectedTab((selectedTab) => selectedTab + 1);
        }
    }, [props.onForwardButtonClicked]);

    useEffect(() => {
        if (props.onMockDataTrigger) {
            for (let category in formData) {
                if (typeof formData[category] === 'object') {
                    for (let subcategory in formData[category]) {
                        if (formData[category][subcategory]) {
                            for (let dataIndex in formData[category][subcategory].data) {
                                formData[category][subcategory].data[dataIndex].note = null;
                                formData[category][subcategory].data[dataIndex].amount = null;
                                formData[category][subcategory].data[dataIndex].frequency = IE_FREQUENCY_TYPE[2].Id;

                                if ((Math.floor(Math.random() * 10) + 1) === 7) {
                                    formData[category][subcategory].data[dataIndex].note = (Math.random() + 1).toString(36).substring(2);
                                }
                                else {
                                    formData[category][subcategory].data[dataIndex].amount = Math.floor(Math.random() * 100) + 1;
                                }
                            }
                        }
                    }
                }
            }

            SummaryCalculator.recalculate(formData);
            setFormData(formData);
            setCategories(formData[tabItems[selectedTab].dataObject])
        }
    }, [props.onMockDataTrigger]);

    const onTabsSelectionChanged = (args) => {
        if (args.name === 'selectedIndex' && args.value > -1) {

            if (args.value > selectedTab && !isformDataValid(true)) {
                showWarnMessage();
                return;
            }

            setSelectedTab(args.value);
        }
    }

    const handlePrimitiveValueChange = (e) => {
        if (typeof formData[tabItems[selectedTab].dataObject][e.title] !== 'object' || e.title === 'note') {
            if (formData[tabItems[selectedTab].dataObject][e.title] !== e.value) {
                switch (e.title) {
                    case 'spouseRefusedToDeclareIncome':
                        formData['income'][e.title] = e.value;
                        for (let index in formData['income']['jointBankruptDebtorIncome'].data)
                            formData['income']['jointBankruptDebtorIncome'].data[index].isDisabled = e.value;
                        break;
                    case 'noAdults':
                    case 'noChildUnder18':
                    case 'note':
                        formData[tabItems[selectedTab].dataObject][e.title] = e.value !== undefined ? e.value : null;
                        break;
                    default:
                        break;
                }

                SummaryCalculator.recalculate(formData);
                setFormData(formData);
                setCategories(formData[tabItems[selectedTab].dataObject]);
            }
        }
    }

    const handleListChangedValue = (e) => {
        const indexToUpdate = formData[tabItems[selectedTab].dataObject][e.parent].data.findIndex(x => x.type === e.title);
        const prevObjectState = { ...formData[tabItems[selectedTab].dataObject][e.parent].data[indexToUpdate] };

        formData[tabItems[selectedTab].dataObject][e.parent].data[indexToUpdate].amount = e.value;
        formData[tabItems[selectedTab].dataObject][e.parent].data[indexToUpdate].frequency = e.frequency;
        formData[tabItems[selectedTab].dataObject][e.parent].data[indexToUpdate].note = e.note;
        formData[tabItems[selectedTab].dataObject][e.parent].data[indexToUpdate].isTouched = e.isTouched;

        SummaryCalculator.recalculate(formData);

        if (prevObjectState.amount !== e.value || prevObjectState.frequency !== e.frequency || prevObjectState.isTouched !== e.isTouched || prevObjectState.note !== e.note)
            setCategories(formData[tabItems[selectedTab].dataObject])

        setFormData({ ...formData });
    }

    const isformDataValid = (setTouched) => {
        let isValid = true;

        for (const prop in formData[tabItems[selectedTab].dataObject]) {
            if (typeof formData[tabItems[selectedTab].dataObject][prop] === "object") {
                var items = formData[tabItems[selectedTab].dataObject][prop];

                if (items !== null && items.data) {
                    if (props.formRecord.isLocked === false && items.data.some(x => !InputValidatorService.isValidField(x)))
                        isValid = false;

                    if (setTouched) {
                        for (const item in items.data)
                            items.data[item].isTouched = true;
                    }
                }
            }
        }

        if (requiresNoteInput())
            isValid = false;

        if (!props.formRecord.date)
            isValid = false;

        setFormData({ ...formData });

        if (setTouched && !isValid)
            setCategories(formData[tabItems[selectedTab].dataObject])

        return isValid;
    }

    const showWarnMessage = () =>
        toast.warn(t('NOTIFICATIONS.FILL_REQUIRED_FIELDS'), { progress: undefined });

    const setCategories = (data) => {
        var categories = IEFormDataService.generateCategories(data, props.formRecord.createdDate, props.settings, props.isJoint);

        setPrimitiveCategories(getPrimitiveCategories(categories));
        setGroupedCategories(getGroupedCategories(categories))
    }

    const getPrimitiveCategories = (data) => {
        return data.filter(x => !x.data && (props.isJoint === false || (x.key !== 'spouseRefusedToDeclareIncome'))).map((item) => <IEFieldContainer
            key={item.key}
            title={item.key}
            value={item.content}
            note={item.note}
            required={item.key === 'note' ? requiresNoteInput() : false}
            elementType={getPrimitiveFieldType(item.key)}
            isLocked={props.formRecord.isLocked}
            isInteger={true}
            isJoint={props.isJoint}
            handleValueChange={handlePrimitiveValueChange} />)
    };

    const getPrimitiveFieldType = (field) => {
        if (field === 'familyUnitMemberRefusesToDeclareIncome' || field === 'spouseRefusedToDeclareIncome')
            return 'boolean';

        if (field === 'noAdults' || field === 'noChildUnder18')
            return 'number';

        if (field === 'note')
            return 'string';

        return null;
    }

    const getGroupedCategories = (data) =>
        <Accordion allowMultiple transition transitionTimeout={250}>
            {data.filter(x => x.items).map((group, i) =>
            (<AccordionItem initialEntered={i === 0} key={group.key} header={
                <GroupHeaderElement>
                    <div className="d-flex flex-row">
                        {hasGroupItemErrors(group)}
                        <label className="group-name">
                            {t(`LABELS.${group.key.toUpperCase()}`)}
                        </label>
                    </div>
                    <label className="group-total">
                        <label>{formatValue(formData[tabItems[selectedTab].dataObject][group.key].total)}</label>
                    </label>
                </GroupHeaderElement>} >
                {group.items.sort((a, b) => {
                    return a.sortOrder - b.sortOrder;
                }).map(getItemRender)}
            </AccordionItem>))}
        </Accordion>

    const getItemRender = (item) => {
        return <IEFieldContainer
            key={item.type + item.parent}
            parent={item.parent}
            sortOrder={item.sortOrder}
            title={item.type}
            value={item.amount}
            note={item.note}
            isIncomeField={tabItems[selectedTab].dataObject === 'income'}
            frequency={item.frequency}
            isTouched={item.isTouched}
            isLocked={props.formRecord.isLocked}
            isDisabled={item.isDisabled}
            elementType={'number'}
            handleValueChange={handleListChangedValue}
            isInteger={false} />
    }

    const hasGroupItemErrors = (group) => {
        if (!group.items.some(x => x.isTouched))
            return <></>;

        if (props.formRecord.isLocked === false && group.items.some(x => !InputValidatorService.isValidField(x)))
            return <ErrorIcon />;

        if (tabItems[selectedTab].dataObject === 'income') {
            if (InputValidatorService.isGroupRequiresNoteInput(group, props.isJoint) && formData['income']['note'] === null)
                return <ErrorIcon />;
        }
       
        return <></>;
    }

    const requiresNoteInput = () => {
        if (tabItems[selectedTab].dataObject !== 'income')
            return false;
        
        if (formData['income']['note'] !== null)
            return false;

        var categories = IEFormDataService.generateCategories(formData[tabItems[selectedTab].dataObject], props.formRecord.createdDate, props.settings, props.isJoint);

        return categories.filter(x => x.items).some(group => InputValidatorService.isGroupRequiresNoteInput(group, props.isJoint));
    }

    const formatValue = (value) => {
        return new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'CAD',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        }).format(value ? value : 0);
    }

    const setSelectedDate = (date) => {
        const momentDate = moment(date).startOf('day');
        const isoDate = momentDate.format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
        setCreatedDate(isoDate);
        props.formRecord.date = isoDate;
    };

    const getRecordDate = () => {
        const date = getLocalDateFromUtc(moment(props.formRecord.date).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')); 
        const options = { year: 'numeric', month: 'long', day: 'numeric' };

        return date.toLocaleDateString(i18n.language, options);
    } 

    const getLocale = () => {
        switch (i18n.language) {
            case 'fr':
                return fr;
            case 'de':
                return de;
            default:
                return enGB;
        }
    }

    const getLocalDateFromUtc = (isoDate) => {
        let date = new Date(isoDate);
        return new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);
    };

    return (
        <IEBaseContainerElement $tabsLength={tabItems.length}>
            {(selectedTab >= 0 && selectedTab < tabItems.length) &&
                <div className="flex-container">
                    <div className="ie-contaier-header">
                        <Tabs dataSource={tabItems} selectedIndex={selectedTab} onOptionChanged={onTabsSelectionChanged} />
                        <div className="summary-items-container">
                            <IESummaryContainer title={t('SUMMARY.INCOME')} value={formData.summary.totalIncome} />
                            <IESummaryContainer title={t('SUMMARY.NON_DISCRETIONARY_EXPENSES')} value={formData.summary.totalNonDiscretionaryExpenses} />
                            <IESummaryContainer title={t('SUMMARY.DISCRETIONARY_EXPENSES')} value={formData.summary.totalDiscretionaryExpenses} />
                            <IESummaryContainer title={formData.summary.totalBalance > 0 ? t('SUMMARY.BALANCE_SURPLUS') : t('SUMMARY.BALANCE_DEFICIT')} value={formData.summary.totalBalance} balance />
                        </div>
                        <div className="ie-header-date-container">
                            {
                                props.formRecord.isLocked &&
                                <div>
                                    {t('LABELS.CURRENT_BUDGET_DATE')}: {getRecordDate()}
                                </div>
                            }
                            {
                                !props.formRecord.isLocked &&
                                <>
                                    {t('LABELS.BUDGET_DATE')}:
                                    <DatePicker
                                        selected={getLocalDateFromUtc(createdDate)}
                                        onChange={(date) => setSelectedDate(date)}
                                        dateFormat="dd MMMM yyyy"
                                        locale={getLocale()}
                                        customInput={<CustomDatePickerInput/>}
                                    />
                                </>
                            }
                        </div>
                    </div>
                   
                    <IECategoryContainerElement>
                        {primitiveCategories}
                        {groupedCategories}
                    </IECategoryContainerElement>
                </div>
            }
            {(selectedTab > 0 && selectedTab === tabItems.length) && <Spinner inner={true} />}
        </IEBaseContainerElement>
    );
}