import 'bootstrap/dist/css/bootstrap.css';
import './configurations/i18n';
import React, { useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { App } from './App';
import * as serviceWorkerRegistration from './configurations/serviceWorkerRegistration';
import reportWebVitals from './configurations/reportWebVitals';
import themes from "devextreme/ui/themes";

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

function Root() {
    function initializeApp() {
        themes.initialized(() => { root.render(<Root />); });
    }

    useEffect(() => { initializeApp(); }, []);

    return (
        <BrowserRouter basename={baseUrl}>
            <App />
        </BrowserRouter>
    );
}

root.render(<Root />);
serviceWorkerRegistration.unregister();
reportWebVitals();
