import styled from 'styled-components';
import { breakpoints } from "../../../../../utils/styledBreakpoints";

export const BottomNavBarElement = styled.div`
    position: fixed;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100vw;
    background: #fff;
    height: 90px;
    padding: 0 30px;
    box-shadow: 0px 0px 6px 0px rgba(185, 186, 202, 0.33);
    z-index: 2;
`;

export const ButtongsGroup = styled.div`
    display: flex;
    gap: 1.4rem;
    ${breakpoints("gap", "rem", [{ 992: `0.75` }])};
    ${breakpoints("flex-basis", "%", [{ 992: `100` }])};
    ${breakpoints("justify-content", "", [{ 992: `space-between` }])};
`;

export const SummaryGroup = styled.div`
    display: flex;
    gap: 1.4rem;
    ${breakpoints("display", "", [{ 992: `none` }])};
`;
