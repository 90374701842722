export const MESSAGE_TYPE = {
    ERROR: 1,
    SUCCESS: 2
};

export const IE_FREQUENCY_TYPE = [
    { Id: 'Weekly', Name: 'FREQUENCY_TYPES.WEEKLY', Color: '#ececfd', TextColor: '#4c60f2' },
    { Id: 'Fortnightly', Name: 'FREQUENCY_TYPES.FORTNIGHTLY', Color: '#d6d5fa', TextColor: '#4c60f2' },
    { Id: 'Monthly', Name: 'FREQUENCY_TYPES.MONTHLY', Color: '#c0bef7', TextColor: '#4c60f2' },
    { Id: 'Quarterly', Name: 'FREQUENCY_TYPES.QUARTERLY', Color: '#aaa7f5', TextColor: '#0d21b1' },
    { Id: 'Annually', Name: 'FREQUENCY_TYPES.ANNUALLY', Color: '#9391f2', TextColor: '#0b1d9a' },
];

export const FINANCIAL_FREQUENCIES = {
    WEEKLY: 'Weekly',
    FORTNIGHTLY: 'Fortnightly',
    FOURWEEKLY: 'Fourweekly',
    MONTHLY: 'Monthly',
    QUARTERLY: 'Quarterly',
    ANNUALLY: 'Annually'
};