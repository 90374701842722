import styled from 'styled-components';
import { breakpoints } from "../../../../utils/styledBreakpoints";

export const SummaryContainerElement = styled.div`
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 0.5rem;
    justify-content: flex-end;

    ${breakpoints("border-radius", "px", [{ 991: `4` }])};
    ${breakpoints("justify-content", "", [{ 991: `end` }])};
    ${breakpoints("align-items", "", [{ 991: `center` }])};

    & label {
        ${breakpoints("text-align", "", [{ 991: `center` }])};
    }

    label {
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        color: #74758A;

        ${breakpoints("line-height", "%", [{ 991: `140` }])};

    }

    label.summary-value {
        font-size: 1.25rem;
        font-weight: 600;
        color: ${props => props.$isBalance ? (props.$isPositive ? "green" : "red") : "var(--sidebar-color)"};
        white-space: nowrap;
    }

    .info-icon {
        height: 100%;
        width: 1rem;
        border-radius: 100%;
        min-height: 1rem;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        z-index: 1;
        background-image: var(--info-icon);

        ${breakpoints("display", "", [{ 991: `none` }])};
    }
`;
