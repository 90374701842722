import { HomeForm65 } from "../FormTypes/Form65/Home";
import { HomeSFS } from "../FormTypes/SFS/Home";

export const Layout = (applicationData) => {

    return (
        <>
            {applicationData.formRecord.formData.type === 'SFS' && (<HomeSFS {...applicationData} />)}
            {applicationData.formRecord.formData.type === 'Form65' && (<HomeForm65 {...applicationData} />)}
        </>
    )
}   