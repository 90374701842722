import React from 'react';

export const CustomDatePickerInput = React.forwardRef(({ value, onClick }, ref) => (
    <input
        type="text"
        value={value}
        onClick={onClick}
        readOnly
        style={{ cursor: 'pointer' }}
        ref={ref}
    />
));