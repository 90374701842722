import React from 'react';
import { SummaryContainerElement } from './style';
import { FormatValue } from '../utils/helpers';

export const IESummaryContainer = (props) => {
    return (
        <SummaryContainerElement $isBalance={props.balance && props.value !== 0} $isPositive={props.value > 0} id={props.id} $showinfoicon={props.showinfoicon}>
            <div className='d-flex justify-content-between gap-standard'><label>{props.title}</label>{props.showinfoicon && <div className='info-icon'></div>}</div>
            
            <label className="summary-value">{FormatValue(props.value)}</label>
            
        </SummaryContainerElement>
    );
}