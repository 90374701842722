import React from 'react';
import { Button } from 'devextreme-react/button';
import { useTranslation } from 'react-i18next';
import { BottomNavBarElement } from './style';
import { ButtongsGroup } from './style';
import { SummaryGroup } from './style';
import { IESummaryContainer } from "../../IESummaryContainer";
import { HouseholdButton } from '../Common/style';
import { Tooltip } from 'devextreme-react/tooltip';
import { FormatValue } from '../../utils/helpers';
import { TriggerFiguresService } from "../../utils/triggerFiguresService";

export const BottomNavBar = (props) => {
    const { t } = useTranslation();

    const getTooltipRow = (key, value) => {
        return <div className='d-flex justify-content-between gap-standard'><span>{t(`LABELS.${key.toUpperCase()}`)}:</span> <strong>{value}</strong    ></div>
    }

    const getUnder16 = () => TriggerFiguresService.getDependantsUnder16(props.householdSize.dependantsInHousehold)
    const getUnder18 = () => TriggerFiguresService.getDependantsUnder18(props.householdSize.dependantsInHousehold)
    const getOther = () => TriggerFiguresService.getOtherDependants(props.householdSize.dependantsInHousehold)

    return (
        <BottomNavBarElement>
            <SummaryGroup>
                <IESummaryContainer title={t('SUMMARY.TOTAL_INCOME')} value={props.summary.totalIncome} />
                <IESummaryContainer id="total-outgoings" title={t('SUMMARY.TOTAL_OUTGOINGS')} $showinfoicon="true" value={props.summary.totalFixedOutgoings + props.summary.totalFlexibleOutgoings} >
                    
                </IESummaryContainer>
                <IESummaryContainer title={t('SUMMARY.CONTRIBUTION')} value={props.summary.contribution} balance />
                <HouseholdButton onClick={props.onHouseholdButtonClicked} id="household-btn">
                    <div className="household-icon"></div>
                    <label>{t('BUTTONS.HOUSEHOLD')}</label>
                </HouseholdButton>

                <Tooltip
                        target="#household-btn"
                        showEvent="dxhoverstart"
                        hideEvent="dxhoverend"
                        position="top">
                    {getTooltipRow("dependantChildrenUnder16", getUnder16())}
                    {getTooltipRow("dependantChildrenUnder18", getUnder18())}
                    {getTooltipRow("otherDependants", getOther())}
                    {getTooltipRow("adultsInHousehold", props.householdSize.adultsInHousehold)}
                </Tooltip>
                
                <Tooltip
                    target="#total-outgoings"
                    showEvent="dxhoverstart"
                    hideEvent="dxhoverend"
                    position="top"> 
                    {getTooltipRow("fixed", FormatValue(props.summary.totalFixedOutgoings))}
                    {getTooltipRow("flexible", FormatValue(props.summary.totalFlexibleOutgoings))}
                </Tooltip>
            </SummaryGroup>

            <ButtongsGroup>
                <Button disabled={props.tabsInfo.currentTab === props.tabsInfo.tabsLength} text={props.tabsInfo.currentTab === 0 ? t('BUTTONS.CANCEL') : t('BUTTONS.BACK')} type="normal" stylingMode="outlined" onClick={props.onBackwardClick} />
                <Button disabled={props.tabsInfo.currentTab === props.tabsInfo.tabsLength} text={props.tabsInfo.currentTab < props.tabsInfo.tabsLength - 1 ? t('BUTTONS.NEXT') : t('BUTTONS.FINISH')} type="default" stylingMode="contained" onClick={props.onForwardClick} />
            </ButtongsGroup>
        </BottomNavBarElement>
    );
}