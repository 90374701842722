import { createGlobalStyle, styled } from 'styled-components';
import { breakpoints } from "../../../../../utils/styledBreakpoints";

export const GlobalStyle = createGlobalStyle`
    .dx-overlay-wrapper {
        position: fixed !important;
        height: 100% !important;
        transform: none !important;
    }

    .dx-popup-wrapper > .dx-overlay-content {
        border-radius: 15px;
        padding: 20px;
    }

        .dx-popup-wrapper > .dx-overlay-content.dx-popup-flex-height {
            padding: 40px;
            width: 600px !important;
            ${breakpoints("width", "", [{ 991: `unset !important` }])};
        }

    .dx-tooltip-wrapper.dx-popup-wrapper > .dx-overlay-content {
        border-radius: 15px;
        padding: 8px;
        width: unset !important;

        ${breakpoints("width", "", [{ 991: `90% !important` }])};
    }

    .dx-popup-content{
        padding: 0;
    }

    .dx-popup-title { 
        border-bottom: none;
        padding: 0;
        margin-top: 0;
        margin-left: 0;
        margin-bottom: 1.5rem;
    }
    
    .dx-numberbox-spin-container {
        height: 50%;
    }

    .dx-numberbox-spin-up-icon::before {
        content: '\\f014';
    }

    .dx-numberbox-spin-down-icon:before {
        content: '\\f016';
    }

    .dx-radiobutton-icon {
        width: 24px;
        height: 24px;
    }

    .dx-radiogroup .dx-radiobutton:first-child {
        margin-top:0;
    }

    .dx-radiogroup .dx-radiobutton:last-child {
        margin-bottom:0;
    }

    .dx-radiobutton-icon-checked .dx-radiobutton-icon-dot {
        border-width: 2px;
    }

    .dx-radiobutton-icon-checked {
        border-color: var(--primary-active);
    }

    .dx-radiobutton-icon-checked .dx-radiobutton-icon-dot {
        background-color: var(--primary-active)
    }

    .dx-button {
        padding: 0.625rem 1.25rem;
        min-width: unset;
    }

    .dx-button-text {
        font-size: 0.875rem;
    }

    .dx-checkbox-icon {
        width: 22px;
        height: 22px;
        border-radius: 5px;
    }

    .dx-popup-wrapper .tooltip-content {
        max-width: 100%;
        white-space: break-spaces;
        word-wrap: break-word;
    }

    .dx-datebox-wrapper .dx-overlay-content.dx-popup-normal {
        max-width: fit-content;
    }
`;

export const HouseholdButton = styled.div`
    display: ${props => props.$hidedesktop ? 'none' : 'flex'};
    gap: 0.7rem;
    align-items: center;
    cursor: pointer;
    font-weight: 600;
    font-size: 1rem;

    ${breakpoints("display", "", [{ 991: 'flex' }])};
    

    & label {
        cursor: pointer;
    }
    
    .household-icon {
        height: 100%;
        width: 2rem;
        border-radius: 100%;
        min-height: 2rem;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        z-index: 1;
        background-image: var(--notes-icon-active);
    }
`;