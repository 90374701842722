import { chain, round } from 'mathjs'
import { FINANCIAL_FREQUENCIES } from '../../../../utils/constants'


export const SummaryCalculator = {
    recalculate: (data) => {
        for (var category in data) {
            if (typeof data[category] === 'object') {
                for (var subcategory in data[category]) {
                    if (data[category][subcategory]) {
                        if (typeof data[category][subcategory] === 'object') {
                            data[category][subcategory].total = calculateBasedOnFrequencyValues(data[category][subcategory].data);
                        }
                    }
                }
            }
        }

        data['summary'].totalIncome = calculateSummaryIncome(data);
        data['summary'].totalNonDiscretionaryExpenses = calculateSummaryExpenses(data, true);
        data['summary'].totalDiscretionaryExpenses = calculateSummaryExpenses(data, false);

        data['summary'].totalBalance = data['summary'].totalIncome - (data['summary'].totalNonDiscretionaryExpenses + data['summary'].totalDiscretionaryExpenses);
    }
};

const calculateBasedOnFrequencyValues = (data) => {
    return data.filter(x => x.type !== 'GrossSelfEmploymentIncome').map(x => getMonthlyValue(x.amount, x.frequency)).reduce((partialSum, a) => partialSum + a, 0)
}

const calculateSummaryIncome = (data) => {
    var result = 0;

    for (var subcategory in data['income']) {
        if (data['income'][subcategory]) {
            if (typeof data['income'][subcategory] === 'object') {
                result = result + data['income'][subcategory].total;
            }
        }
    }

    return result;
}

const calculateSummaryExpenses = (data, nonDiscretionary) => {
    var result = 0;
    for (var subcategory in data['expenses']) {
        if ((nonDiscretionary === false ? subcategory.indexOf('nonDiscretionary') === -1 : subcategory.indexOf('nonDiscretionary') > -1) && data['expenses'][subcategory]) {
            if (typeof data['expenses'][subcategory] === 'object') {
                result = result + data['expenses'][subcategory].total;
            }
        }
    }

    return result;
}

const getMonthlyValue = (value, frequency) => {
    if (!value)
        value = 0;

    var convertedValue = null;

    switch (frequency) {
        case FINANCIAL_FREQUENCIES.WEEKLY:
            convertedValue = chain(value).multiply(52).divide(12).done();
            break;
        case FINANCIAL_FREQUENCIES.FORTNIGHTLY:
            convertedValue = chain(value).multiply(26).divide(12).done();
            break;
        case FINANCIAL_FREQUENCIES.FOURWEEKLY:
            convertedValue = chain(value).multiply(13).divide(12).done();
            break;
        case FINANCIAL_FREQUENCIES.MONTHLY:
            convertedValue = value;
            break;
        case FINANCIAL_FREQUENCIES.QUARTERLY:
            convertedValue = chain(value).divide(3).done();
            break;
        case FINANCIAL_FREQUENCIES.ANNUALLY:
            convertedValue = chain(value).divide(12).done();
            break;
        default:
            convertedValue = value;
    }

    return round(convertedValue, 2)
}