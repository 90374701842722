import React from 'react';
import { SpinnerElement, IconSpinner } from './style';

export const Spinner = (props) => {
    return (
        <SpinnerElement $isInner={props.inner === true}>
            <IconSpinner>
                <div>
                    <svg width="100%" height="100%" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
                        <g>
                            <animateTransform attributeName="transform" type="rotate" values="0 33 33;270 33 33" begin="0s"
                                dur="1.4s" fill="freeze" repeatCount="indefinite" />
                            <circle className="spinner" fill="none" strokeWidth="6" strokeLinecap="round" cx="33" cy="33" r="30"
                                strokeDasharray="187" strokeDashoffset="610">
                                <animateTransform attributeName="transform" type="rotate" values="0 33 33;135 33 33;450 33 33"
                                    begin="0s" dur="1.4s" fill="freeze" repeatCount="indefinite" />
                                <animate attributeName="strokeDashoffset" values="187;46.75;187" begin="0s" dur="1.4s"
                                    fill="freeze" repeatCount="indefinite" />
                            </circle>
                        </g>
                    </svg>
                </div>
            </IconSpinner>
        </SpinnerElement>
    );
}