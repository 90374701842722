import axios from 'axios';
import i18next from 'i18next';

const ax = axios.create();

axios
    .get("i18n/GetIncomeExpendituresApiUrl")
    .then(function (response) {
        ax.defaults.baseURL = response.data + '/api';
    });

const responseBody = (response) => response.data;

// Add a request interceptor
ax.interceptors.request.use(
    config => {
        config.headers['Custom_Language'] = i18next.language;

        return config
    },
    error => {
        Promise.reject(error)
    }
)

export const Request = {
    get: (url, params) => ax.get(url, { params: params }).then(responseBody),
    post: (url, body) => ax.post(url, body).then(responseBody),
};