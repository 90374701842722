import styled from 'styled-components';
import { breakpoints } from "../../../../../utils/styledBreakpoints";


export const HouseholdDependantsContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1.14rem;
    overflow: auto;
    max-height: 50vh;

    .adult-input {
        display: flex;
        flex-direction: column;
        gap: 0.7rem;
    }

    label {
        font-size: 1rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        display: flex;
        align-items: center;
    }

    & .household-input {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        gap: 0.7rem;
    }

    & .household-input:nth-child(-n+3)  {
       flex-basis: calc(33% - (4px * 3));
       ${breakpoints("flex-basis", "%", [{ 991: `100` }])};
    }

     & .household-input:nth-child(n+4)  {
       flex-basis: calc(50% - (4px * 3));
       ${breakpoints("flex-basis", "%", [{ 991: `100` }])};
    }
`;

export const DependantsContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 1.14rem;

    ${breakpoints("gap", "rem", [{ 991: `2.7` }])};
    ${breakpoints("padding-bottom", "rem", [{ 991: `1` }])};

    .dependant-row {
        .item-1 { grid-area: item1; }
        .item-2 { grid-area: item2; }
        .item-3 { grid-area: item3; }

        display: grid;
        grid-template-columns: 3fr;
        grid-template-columns: 50% auto 20%;
        grid-template-areas: "item1 item2 item3";
        align-items: center;
        gap: 0.7rem;


        ${breakpoints("grid-template-columns", "fr", [{ 991: `1` }])};
        ${breakpoints("grid-template-areas", "", [{
            991: `" item1 "
                  " item2 "
                  " item3 "` }])};

        .item-3 label {
            ${breakpoints("display", "", [{ 991: `none` }])};
        }

        .dx-datebox .dx-texteditor-buttons-container {
            ${breakpoints("display", "", [{ 991: `none` }])};
        }

        .dx-datebox .dx-texteditor-input {
            ${breakpoints("padding", "", [{ 991: `15px !important` }])};
        }
    }

    .dependant-row div {
        gap: 0.7rem;
    }
`;
