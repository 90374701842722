import styled from 'styled-components';
import { breakpoints } from "../../../../utils/styledBreakpoints";

export const NumberInputContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    gap: 1rem;

    & .header {
        display: flex;
        gap: 1.71rem;
        flex-basis: 100%;
        justify-content: space-between;
        align-items: center;
    }

    & .dx-overlay.dx-popup.dx-widget.dx-visibility-change-handler {
        position: fixed;
    }

    div.d-flex.flex-row {
        display: flex;
        flex-basis: 100%;
        flex-direction: row;
        gap: 0.7rem;
        ${breakpoints("flex-basis", "%", [{ 991: `100` }])};
    }

    label {
        font-size: 1rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        display: flex;
        align-items: center;
    }

    .dx-dropdowneditor {
        width: 25%;
        ${breakpoints("width", "%", [{ 991: `70` }])};
    }

    .dx-texteditor.dx-editor-filled::after {
        border: none;
    }

    .dx-numberbox {
        width: 100%
    }

    .note-label {
        font-size: 0.8rem;
    }
`;


export const NumberSelectContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    ${breakpoints("flex-basis", "%", [{ 991: `100` }])};

    div.d-flex.flex-row {
        display: flex;
        flex-direction: column;
        gap: 0.7rem;
        
    }

    label {
        font-size: 1rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-bottom: 0.5rem;
    }

    .dx-dropdowneditor {
         width: calc((100% - 3.5rem) / 3);
        ${breakpoints("width", "%", [{ 991: `100` }])};
    }

    .dx-texteditor.dx-editor-filled::after {
        border: none;
    }

    .dx-numberbox {
        width: 100%
    }

    .dx-numberbox input {
        text-align: right;
    }

    .note-label {
        font-size: 0.8rem;
    }
`;

export const CheckBoxInputContainer = styled.div`
    display: flex;
    flex-basis: 100%;
`;

export const TextareaInputContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    order: 99;

    label {
        font-size: 1rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-bottom: 0.5rem;
    }
`;

export const FrequencyButton = styled.div`
    display: block;
    text-align: center;
    font-size: 0;
    line-height: 2rem;
    height: 2rem;
    width: 2rem;
    border-radius: 4px;
    background-color: ${props => props.$color};
    color: ${props => props.$textcolor};
    cursor: pointer;
    min-width: 2rem;

    &:hover {
        background-color: #b6bef5;
    }

    .freq:first-letter {
      font-size: 1rem;
      font-weight: 700;
    }
`;

export const NotesModalButton = styled.div`
    height: 100%;
    width: 2rem;
    border-radius: 100%;
    background-position: center;
    background-repeat: no-repeat;
    margin-left: -45px;
    background-size: contain;
    z-index: 1;
    background-image: ${props => !props.$hasNote ? (props.$noteRequired ? 'var(--notes-icon-error)' : 'var(--notes-icon)') : 'var(--notes-icon-active)'};

    &:hover {
        background-image: var(--notes-icon-active)
    }
`;