import React from 'react';
import { LogoContainerElement, LogoElement } from './style';

export const LogoContainer = (props) => {
    const imagePath = require('../../../resources/organizationLogos/default.svg');

    return (
        <LogoContainerElement>
            <LogoElement $logoURL={props.logoURL ? props.logoURL : imagePath.default} $mobileLogoURL={props.mobileLogoURL ? props.mobileLogoURL : (props.logoURL ? props.logoURL : imagePath.default)}>
                <img alt="Logo" />
            </LogoElement>
        </LogoContainerElement>
    );
}