export const InputValidatorService = {
    isValidField: (field) => isValidField(field)
};

const isValidField = (x) => {
    if (x.type.toLowerCase().indexOf('other') === -1)
        return x.amount >= 0;

    if (x.type.toLowerCase().indexOf('other') > -1)
        return x.amount > 0 ? (x.note !== undefined && x.note !== null && x.note !== '') : x.amount === 0;

    if (!x.isDisabled && x.isTouched)
        return true;
}