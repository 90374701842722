import React, { useState, useEffect } from 'react';
import Tabs from 'devextreme-react/tabs';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { IEBaseContainerElement, IECategoryContainerElement, GroupContainer } from './style';
import { IEFormDataService } from '../utils/ieFormDataService';
import { TriggerFiguresService } from "../utils/triggerFiguresService";
import { SummaryCalculator } from '../utils/summaryCalculator';
import { InputValidatorService } from '../utils/validatorService';
import { IEFieldContainer } from "../IEFieldContainer";
import { IESummaryContainer } from "../IESummaryContainer";
import { IE_FREQUENCY_TYPE } from '../../../../utils/constants';
import { FLEXIBLE_EXPENSES } from '../../SFS/utils/constants';
import { Spinner } from "../../../_misc/Spinner";
import { HouseholdButton } from '../_misc/Common/style';
import { Tooltip } from 'devextreme-react/tooltip';
import { isMobile } from 'react-device-detect';
import { Accordion, AccordionItem } from '@szhsin/react-accordion';

import "react-datepicker/dist/react-datepicker.css";
import 'moment-timezone';


export const IEFormSFSBaseContainer = (props) => {
    const { t } = useTranslation();
    const [tabItems] = useState(IEFormDataService.generateTabs(props.formRecord.formData).map(x => { return { ...x, text: t(`LABELS.${x[isMobile? 'dataObjectMobile' : 'dataObject'].toUpperCase()}`) } }));
    const [selectedTab, setSelectedTab] = useState(0);
    const [formData, setFormData] = useState(props.formRecord.formData);
    const [primitiveCategories, setPrimitiveCategories] = useState(<></>);
    const [groupedCategories, setGroupedCategories] = useState(<></>);

    useEffect(() => {
        if (selectedTab < tabItems.length){
            SummaryCalculator.recalculate(formData);
            setCategories(selectedTab);
        }
        props.handleStateChanges({ currentTab: selectedTab, tabsLength: tabItems.length, dataToSave: selectedTab === tabItems.length ? { ...props.formRecord, formData: formData } : null });

        document.body.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    }, [selectedTab]);

    useEffect(() => {
        if (selectedTab < tabItems.length) {
            SummaryCalculator.recalculate(formData);
            setCategories(selectedTab);
        }
    }, [props.formRecord.formData.householdDetails]);

    useEffect(() => {
        if (props.onBackwardButtonClicked)
            setSelectedTab((selectedTab) => selectedTab - 1);
    }, [props.onBackwardButtonClicked]);

    useEffect(() => {
        if (props.onForwardButtonClicked) {
            if (!isformDataValid(true)) {
                showWarnMessage();
                return;
            }
            setSelectedTab((selectedTab) => selectedTab + 1);
        }
    }, [props.onForwardButtonClicked]);

    useEffect(() => {
        if (props.onMockDataTrigger) {
            for (let category in formData) {
                if (typeof formData[category] === 'object') {
                    for (let subcategory in formData[category]) {
                        if (formData[category][subcategory]) {
                            for (let dataIndex in formData[category][subcategory].data) {
                                formData[category][subcategory].data[dataIndex].note = null;
                                formData[category][subcategory].data[dataIndex].amount = null;
                                formData[category][subcategory].data[dataIndex].frequency = IE_FREQUENCY_TYPE[2].Id;

                                if ((Math.floor(Math.random() * 10) + 1) === 7) {
                                    formData[category][subcategory].data[dataIndex].note = (Math.random() + 1).toString(36).substring(2);
                                }
                                else {
                                    formData[category][subcategory].data[dataIndex].amount = Math.floor(Math.random() * 100) + 1;
                                }
                            }
                        }
                    }
                }
            }

            SummaryCalculator.recalculate(formData);
            setFormData({...formData});
            setCategories(selectedTab)
        }
    }, [props.onMockDataTrigger]);

    useEffect(() => {
        props.handleSummaryChanged({...formData.summary});
    }, [formData]);

    const onTabsSelectionChanged = (args) => {
        if (args.name === 'selectedIndex' && args.value > -1) {

            if (args.value > selectedTab && !isformDataValid(true)) {
                showWarnMessage();
                return;
            }

            setSelectedTab(args.value);
        }
    }

    const handlePrimitiveValueChange = (e) => {
        if (typeof getTabCategories(selectedTab)[e.title] !== 'object' || e.title === 'note') {
            if (getTabCategories(selectedTab)[e.title] !== e.value) {
                switch (e.title) {
                    case 'spouseRefusedToDeclareIncome':
                        formData['income'][e.title] = e.value;
                        for (let index in formData['income']['jointBankruptDebtorIncome'].data)
                            formData['income']['jointBankruptDebtorIncome'].data[index].isDisabled = e.value;
                        break;
                    case 'noAdults':
                    case 'noChildUnder18':
                    case 'note':
                        getTabCategories(selectedTab)[e.title] = e.value !== undefined ? e.value : null;
                        break;
                    default:
                        break;
                }

                SummaryCalculator.recalculate(formData);

                setFormData(formData);
                setCategories(selectedTab);
            }
        }
    }

    const handleListChangedValue = (e) => {
        const indexToUpdate = getTabCategories(selectedTab)[e.parent].data.findIndex(x => x.type === e.title);
        const prevObjectState = { ...getTabCategories(selectedTab)[e.parent].data[indexToUpdate] };

        getTabCategories(selectedTab)[e.parent].data[indexToUpdate].amount = e.value;
        getTabCategories(selectedTab)[e.parent].data[indexToUpdate].frequency = e.frequency;
        getTabCategories(selectedTab)[e.parent].data[indexToUpdate].note = e.note;
        getTabCategories(selectedTab)[e.parent].data[indexToUpdate].isTouched = e.isTouched;

        SummaryCalculator.recalculate(formData);


        if (prevObjectState.amount !== e.value || prevObjectState.frequency !== e.frequency || prevObjectState.isTouched !== e.isTouched)
            setCategories(selectedTab)

        setFormData({...formData});
    }

    const isformDataValid = (setTouched) => {
        let isValid = true;
        const currentTabCategory = tabItems[selectedTab].dataObject;

        for (const prop in getTabCategories(selectedTab)) {
            if (typeof getTabCategories(selectedTab)[prop] === "object") {
                var items = getTabCategories(selectedTab)[prop];

                if (items !== null && items.data) {
                    if ((currentTabCategory === "fixed_expenditure" && FLEXIBLE_EXPENSES.includes(prop)) ||
                        (currentTabCategory === "flexible_expenditure" && !FLEXIBLE_EXPENSES.includes(prop))) {
                        continue;
                    }

                    if (props.formRecord.isLocked === false && items.data.some(x => !InputValidatorService.isValidField(x)))
                        isValid = false;

                    if (setTouched) {
                        for (const item in items.data)
                            items.data[item].isTouched = true;
                    }
                }
            }
        }

        if (!props.formRecord.createdDate)
            isValid = false;

        setFormData({ ...formData });

        if (setTouched && !isValid)
            setCategories(selectedTab)

        return isValid;
    }

    const showWarnMessage = () =>
        toast.warn(t('NOTIFICATIONS.FILL_REQUIRED_FIELDS'), { progress: undefined });

    const setCategories = (selectedTab) => {
        var data = getTabCategories(selectedTab);
        
        var categories = IEFormDataService.generateCategories(data, tabItems[selectedTab].dataObject);
        setPrimitiveCategories(getPrimitiveCategories(categories));
        setGroupedCategories(getGroupedCategories(categories))
    }

    const getTabCategories = (selectedTab) => {
        var tabName = tabItems[selectedTab].dataObject;

        if (tabName === 'income')
            return formData[tabName];

        if (tabName.indexOf('expenditure') > -1)
            return formData['expenditure'];
    }

    const getPrimitiveCategories = (data) => {
        return data.filter(x => !x.data && (props.isJoint === false || (x.key !== 'spouseRefusedToDeclareIncome'))).map((item) => <IEFieldContainer
            key={item.key}
            title={item.key}
            value={item.content}
            note={item.note}
            elementType={getPrimitiveFieldType(item.key)}
            isLocked={props.formRecord.isLocked}
            isInteger={true}
            isJoint={props.isJoint}
            handleValueChange={handlePrimitiveValueChange} />)
    };

    const getPrimitiveFieldType = (field) => {
        if (field === 'familyUnitMemberRefusesToDeclareIncome' || field === 'spouseRefusedToDeclareIncome')
            return 'boolean';

        if (field === 'noAdults' || field === 'noChildUnder18')
            return 'number';

        if (field === 'note')
            return 'string';

        return null;
    }

    const labelsToTransform = ['bankruptDebtorIncome', 'nonDiscretionaryBankruptDebtor'];

    const getGroupTotalLabel = (key) => {
        if (!TriggerFiguresService.hasTriggerFiguresConfigured(key, props.triggerFigures)) {
            return <>
                <label className="group-total">
                    <label>{formatValue(getTabCategories(selectedTab)[key].total)}</label>
                </label>
            </>;
        }

        var triggerValue = TriggerFiguresService.calculateTriggerFigures(key, props.triggerFigures, props.formRecord.formData.householdDetails);
        if (triggerValue < getTabCategories(selectedTab)[key].total) {
            return <>
                <label id={key + 'label'} className="group-total exceeded-guidelines">
                    <div className="alert-icon"></div>
                    <label>{formatValue(getTabCategories(selectedTab)[key].total)}</label>

                    <Tooltip
                        target={'#' + key + 'label'}
                        showEvent={isMobile ? "mouseenter" : "dxhoverstart"}
                        hideEvent={isMobile ? "mouseleave" : "dxhoverend"}
                        position="top"
                        maxWidth="90%">
                            <div className="tooltip-content">{`Amount entered per month (${getTabCategories(selectedTab)[key].total}) for total ${t(`LABELS.${key.toUpperCase()}`)} exceeds the allowed monthly amount (${triggerValue})`}</div>
                    </Tooltip>
                </label>
            </>;
        }


        return <>
            <label className="group-total">
                <label>{formatValue(getTabCategories(selectedTab)[key].total)}</label>
            </label>
        </>;
    }

    const getGroupedCategories = (data) =>
        {
            let items = data.filter(x => x.items);

            
      
            return isMobile ? 
                        <GroupContainer>
                            <Accordion allowMultiple transition transitionTimeout={250}>
                        {items.map((group, i) =>
                            (<AccordionItem initialEntered={false} key={group.key} header={GetGroupTitle(group)} >
                                {GetGroupContent(group)}
                            </AccordionItem>))}
                        </Accordion>
                        </GroupContainer>
                        : 
                        items.map((group) =>
                            (<GroupContainer className={group.key} key={group.key}>
                                {GetGroupTitle(group)}
                                {GetGroupContent(group)}
                            </GroupContainer>));
        }
      
        const GetGroupTitle = (group) => {
            return (<div className="group-header">
                        <label className="group-name">
                            {labelsToTransform.includes(group.key) && t(`LABELS.${group.key.toUpperCase() + (props.isJoint ? '_JOINT' : '')}`)}
                            {!labelsToTransform.includes(group.key) && t(`LABELS.${group.key.toUpperCase()}`)}
                        </label>
                        { getGroupTotalLabel(group.key) }
                    </div>)
        }
      
        const GetGroupContent = (group) => {
            return (<div className="group-content">
                        {group.items.sort((a, b) => { return a.sortOrder - b.sortOrder; }).map(getItemRender)}
                    </div>);
        }



    const getItemRender = (item) => {
        return <IEFieldContainer
            key={item.type + item.parent}
            parent={item.parent}
            sortOrder={item.sortOrder}
            title={item.type}
            value={item.amount}
            note={item.note}
            isIncomeField={tabItems[selectedTab].dataObject === 'income'}
            frequency={item.frequency}
            isTouched={item.isTouched}
            isLocked={props.formRecord.isLocked}
            isDisabled={item.isDisabled}
            elementType={'number'}
            handleValueChange={handleListChangedValue}
            isInteger={false} />
    }

    const formatValue = (value) => {
        return new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'GBP',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        }).format(value ? value : 0);
    }

    return (
        <IEBaseContainerElement $tabsLength={tabItems.length}>
            {(selectedTab >= 0 && selectedTab < tabItems.length) &&
                <div className="flex-container">
                    <div className="ie-contaier-header">
                        <div className="ie-contaier-header-text">{t('PAGE.HEADER')}</div>

                        <Tabs dataSource={tabItems} selectedIndex={selectedTab} onOptionChanged={onTabsSelectionChanged} scrollByContent={false} showNavButtons={false}/>

                        {formData.summary && (
                            <div className="summary-items-container">
                                <IESummaryContainer title={t('SUMMARY.TOTAL_INCOME')} value={formData.summary.totalIncome} />
                                <IESummaryContainer title={t('SUMMARY.TOTAL_OUTGOINGS')} $showinfoicon="true" value={formData.summary.totalFixedOutgoings + formData.summary.totalFlexibleOutgoings} />
                                <IESummaryContainer title={t('SUMMARY.CONTRIBUTION')} value={formData.summary.contribution} balance />
                            </div>
                        )}
                    </div>

                    <HouseholdButton onClick={props.onHouseholdButtonClicked} $hidedesktop="true" className="mt-2">
                        <div className="household-icon"></div>
                        <label>{t('BUTTONS.HOUSEHOLD')}</label>
                    </HouseholdButton>
                    
                    <IECategoryContainerElement>
                        {primitiveCategories}
                        {groupedCategories}
                    </IECategoryContainerElement>
                </div>
            }
            {(selectedTab > 0 && selectedTab === tabItems.length) && <Spinner inner={true} />}
        </IEBaseContainerElement>
    );
}