import React from 'react';
import { ToastContainer } from 'react-toastify';

export const ToastRootContainer = () => {
    return (<ToastContainer
        position="top-right"
        hideProgressBar={false}
        closeOnClick
        pauseOnHover
        draggable={false}
        autoClose={5000}
        theme="colored"
        newestOnTop
    />);
}