import styled from 'styled-components';
import { breakpoints } from "../../../../utils/styledBreakpoints";

export const IEBaseContainerElement = styled.div`
    display: flex;
    flex-direction: column;
    background: #fff;
    margin: 2.8rem;
    border-radius: 0.71429rem;
    background: var(--bgbg-white, #FFF);
    box-shadow: 0px 0px 6px 0px rgba(185, 186, 202, 0.33);
    height: fit-content;
    min-height: calc(100vh - 5.6rem - 90px);
    width: 100%;
    padding: 1.7rem;
    font-family: 'Poppins';
    margin-bottom: 9rem;

    ${breakpoints("width", "", [{ 991: `auto` }])};
    ${breakpoints("margin", "rem", [{ 991: 0 }])};
    ${breakpoints("padding-bottom", "px", [{ 991: 120 }])};
    ${breakpoints("flex-grow", "", [{ 991: `1` }])};
    ${breakpoints("margin-top", "px", [{ 991: 20 }])};
    ${breakpoints("border", "px", [{ 991: 20 }])};
    ${breakpoints("border-top-left-radius", "px", [{ 991: 20 }])};
    ${breakpoints("border-top-right-radius", "px", [{ 991: 20 }])};

    .flex-container {
        display: flex;
        flex-direction: column;
        gap: 1.7rem;

        ${breakpoints("gap", "rem", [{ 991: 0 }])};
    }

    .ie-contaier-header {
        display: flex;
        flex-direction: row;
        height: fit-content;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        flex-wrap: wrap;
        ${breakpoints("flex-direction", "", [{ 991: `column` }])};
    }

    .ie-contaier-header .ie-header-date-container {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        line-height: initial;
        font-size: 0.92857rem;
        font-style: normal;
        font-weight: 700;
        line-height: 1.25rem;
        letter-spacing: 0.03714rem;
        text-transform: uppercase;
        flex-basis: 100%;
        margin-top: 1rem;
        order: 4;
        gap: 0.5rem;
        ${breakpoints("order", "", [{ 991: `2` }])};
        ${breakpoints("width", "%", [{ 991: `100` }])};
    }
    .ie-contaier-header .ie-header-date-container .react-datepicker-wrapper {
        width: 100%;
    }

    .ie-contaier-header .ie-header-date-container .react-datepicker-wrapper input {
        padding: 15px 16px 14px;
        font-size: 0.92857rem;
        font-style: normal;
        line-height: 1.25rem;
        letter-spacing: 0.03714rem;
        text-transform: uppercase;
        font-family: Roboto,RobotoFallback,"Noto Kufi Arabic",Helvetica,Arial,sans-serif;
        line-height: 1.2857;
        border-radius: 4px;
        border: 1px solid #949494;
        outline: none;
        width: calc((100% - 3.5rem) / 3);
        ${breakpoints("width", "%", [{ 991: `100` }])};
    }

    .react-datepicker__triangle {
        display: none;
    }

    .ie-contaier-header .ie-header-date-container .react-datepicker-wrapper input.react-datepicker-ignore-onclickoutside {
        outline: 1.5px solid var(--primary-active);
    }

     .react-datepicker {
        box-shadow: 0 2px 7px rgba(0,0,0,.2);
        border: 0;
     }

    .react-datepicker,
    .react-datepicker__month-text--today {
        font-family: Roboto,RobotoFallback,"Noto Kufi Arabic",Helvetica,Arial,sans-serif;
        font-weight: 400 !important;
    }

    .react-datepicker__day--keyboard-selected {
        background-color: none;
    }

    .react-datepicker__day--selected,
    .react-datepicker__month-text--keyboard-selected {
        color: #000;
        background-color: rgba(0,0,0,.12);
        font-weight: bold !important;
    }

    .react-datepicker__month .react-datepicker__month-text {
        padding: 14px 16px 13px;
    }

    .ie-contaier-header .summary-items-container {
        display: flex;
        flex-direction: row;
        gap: 2.8rem;
        order: 3;
        ${breakpoints("margin-top", "rem", [{ 991: `1.7` }])};
        ${breakpoints("gap", "rem", [{ 991: `1` }])};
        ${breakpoints("flex-wrap", "", [{ 991: `wrap` }])};
    }

    .dx-tabs {
        height: fit-content;
        border-radius: 7.14286rem;
        padding: 0.25rem;
        width: 40%;
        background: var(--bg-blue-01, #EDEFFE);
        order: 1;
        ${breakpoints("width", "%", [{ 991: `100` }])};
    }

    .dx-tabs-wrapper {
        display: flex;
        flex-direction: row;
    }

    .dx-tab {
        border-radius: 3.57143rem;
        display: flex;
        align-items: center;
        justify-content: center;
        background: var(--bg-blue-01, #EDEFFE);
        flex-basis: calc(100% / ${props => props.$tabsLength})
    }

    .dx-tab.dx-tab-selected {
        background: var(--primary-active, #4C60F2);
        color: var(--button-primary-text-color, #FFFFFF);
    }

    .dx-tab.dx-tab-selected::before {
        display: none;
    }

    .dx-tab-content, .dx-tab-text {
        line-height: initial;
        font-size: 0.92857rem;
        font-style: normal;
        font-weight: 700;
        line-height: 1.25rem;
        letter-spacing: 0.03714rem;
        text-transform: uppercase;
    }

    .szh-accordion {
        width: 100%;
    }

    .szh-accordion__item-heading {
        font-size: 0 !important;
    }

    .szh-accordion__item-heading:hover {
        cursor: pointer;
        background-color: #F7F8FC;
    }

    .szh-accordion__item-heading button {
        outline: none;
        border: none;
        background-color: unset;
        width: 100%;
        padding: 0;
        padding-top: 1.25rem;
        padding-bottom: 1.25rem;
        display: flex;
        align-items: center;   
        padding-right: 1rem;
    }

    .szh-accordion__item-heading button[aria-expanded="false"]:before {
        content: "";
        padding-left: 3rem;
        background-image: url("data:image/svg+xml,%3Csvg width='16' height='17' viewBox='0 0 16 17' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13 6L8 11L3 6' stroke='%23A5A6BF' stroke-width='1.54019' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
        background-repeat: no-repeat;
        background-position-y: center;
        background-position-x: 0.75rem;
        background-size: 1.25rem;
        width: 1.25rem;
        height: 1.25rem;
        display: flex;
        transition: 0.1s ease;
    }

     .szh-accordion__item-heading button[aria-expanded="true"]:before {
        content: "";
        padding-left: 3rem;
        background-image: url("data:image/svg+xml,%3Csvg width='16' height='17' viewBox='0 0 16 17' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13 6L8 11L3 6' stroke='%23A5A6BF' stroke-width='1.54019' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
        background-repeat: no-repeat;
        background-position-x: 0.75rem;
        background-size: 1.25rem;
        width: 1.25rem;
        height: 1.25rem;
        display: flex;
        transform: rotate(180deg);
        transition: 0.1s ease;
    }

    .szh-accordion__item-content {
        padding-top: 0.7rem;
        transition: height 0.25s cubic-bezier(0, 0, 0, 1);
    }

    .szh-accordion__item:not(:last-child) {
        border-bottom: 1px solid rgb(204, 205, 221);
    }

    .szh-accordion__item.szh-accordion__item--expanded {
        padding-bottom: 1.7rem;
    }

    .szh-accordion__item-panel {
        display: flex;
        flex-wrap: wrap;
        gap: 1.7rem;
    }
`;

export const IECategoryContainerElement = styled.div`
    display: flex;
    gap: 1.7rem;
    flex-wrap: wrap;
    ${breakpoints("margin-top", "rem", [{ 991: `1.7` }])};
`;

export const ErrorIcon = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    &:after {
        width: 22px;
        height: 22px;
        font-size: 14px;
        font-weight: 500;
        background-color: #f44336;
        color: #fff;
        content: "!";
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 1rem;
    }
`;

export const GroupHeaderElement = styled.div`
    display: flex;
    flex-direction: row;
    flex-basis: 100%;
    justify-content: space-between;

    font-size: 1.25rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    ${breakpoints("font-size", "rem", [{ 991: `1` }])};

    .group-name {
        display: flex;
        flex-direction: row;
        align-items: center;
        text-align: start;
    }

    .group-total {
        text-align: end;
    }
`;