import styled from 'styled-components';

export const BottomNavBarElement = styled.div`
    position: fixed;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100vw;
    background: #fff;
    height: 90px;
    padding: 0 30px;
    box-shadow: 0px 0px 6px 0px rgba(185, 186, 202, 0.33);
`;