import styled from 'styled-components';
import { breakpoints } from "../../../../utils/styledBreakpoints";

export const SummaryContainerElement = styled.div`
    display: flex;
    flex-direction: column;
    align-items: end;
    gap: 0.5rem;

    ${breakpoints("padding", "rem", [{ 991: `1` }])};
    ${breakpoints("border-radius", "px", [{ 991: `4` }])};
    ${breakpoints("flex-basis", "%", [{ 991: `47` }])};
    ${breakpoints("box-shadow", "", [{ 991: `0px 0px 6px 0px rgba(185, 186, 202, 0.33)` }])};
    ${breakpoints("justify-content", "", [{ 991: `space-between` }])};
    ${breakpoints("align-items", "", [{ 991: `center` }])};

    & label {
        ${breakpoints("text-align", "", [{ 991: `center` }])};
    }

    label {
        font-size: 1rem;
        font-style: normal;
        font-weight: 600;
        line-height: normal;

        ${breakpoints("line-height", "%", [{ 991: `140` }])};

    }

    label.summary-value {
        font-size: 1.25rem;
        font-weight: 600;
        color: ${props => props.$isBalance ? (props.$isPositive ? "green" : "red") : "var(--sidebar-color)"}
    }
`;
