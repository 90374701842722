import React, { useState, useEffect } from 'react';
import themes from "devextreme/ui/themes";
import uuid from 'react-uuid';
import { useTranslation, I18nextProvider } from 'react-i18next';
import { useSearchParams } from "react-router-dom";
import { toast } from 'react-toastify';
import { APIService } from '../src/api/service';
import { ToastRootContainer } from "./components/_misc/ToastContainer";
import { MessagePanel } from "./components/_misc/MessagePanel";
import { Spinner } from "./components/_misc/Spinner";
import { Layout } from "./components/Layout";

export const App = () => {
    const { t, i18n } = useTranslation();
    const [applicationData, setApplicationData] = useState(null);
    const [searchParams] = useSearchParams();
    const pathname = window.location.pathname.replace('/', '');

    useEffect(() => {
        if (pathname && searchParams.get("token")) {
            if (!sessionStorage.getItem('sessionId')) {
                var sessionId = uuid();
                sessionStorage.setItem('sessionId', sessionId);
            }

            APIService.validateApplicationData({ UId: pathname, Token: searchParams.get("token"), SessionId: sessionStorage.getItem('sessionId') })
                .then((response) => {
                    setApplicationData(response);
                    if (response.formRecord) {
                        applyTranslation(response.formRecord.formData.type, response.languageKey);
                        themes.current(applyTheme(response.theme));
                        applyColoring(response);
                    }
                })
                .catch(error => {
                    console.log(error)
                    toast.error(`${t('NOTIFICATIONS.UNEXPECTED_ERROR')}: ${error.message}`, { progress: undefined })
                });
        }
    }, []);

    useEffect(() => {
        document.title = t("PAGE.TITLE");
    }, [i18n.language]);

    const applyTranslation = (namespace, languageKey) => {
        if (!i18n.options.ns.some(x => x === namespace))
            i18n.setDefaultNamespace(namespace);

        if (i18n.language.indexOf(languageKey) === -1)
            i18n.changeLanguage(languageKey);
    }

    const applyTheme = (theme) => {
        switch (theme) {
            default:
                return 'material.blue.light';
        }
    }
    const applyColoring = (response) => {
        if (!response)
            return;

        if (response.primaryColor)
            document.documentElement.style.setProperty('--sidebar-color', response.primaryColor);

        if (response.buttonColor) {
            document.documentElement.style.setProperty('--primary-active', response.buttonColor);
            document.documentElement.style.setProperty('--button-primary-color', response.buttonColor);
            document.documentElement.style.setProperty('--button-primary-color-hover', response.buttonColor + 'E6');
            document.documentElement.style.setProperty('--button-primary-color-focused', response.buttonColor + 'E6');
            document.documentElement.style.setProperty('--button-primary-color-active', response.buttonColor + 'CC');

            if (response.buttonTextColor)
                document.documentElement.style.setProperty('--button-primary-text-color', response.buttonTextColor);
        }
    }

    return (
        <I18nextProvider i18n={i18n}>
            {(window.location && searchParams.get("token")) &&
                <>
                    {!applicationData && (<Spinner />)}
                    {(applicationData && applicationData.success) && (<Layout {...applicationData} />)}
                    {(applicationData && !applicationData.success) && (<MessagePanel {...applicationData} instantRedirect={false} />)}
                </>
            }
            {(!window.location || searchParams.get("token") === null || searchParams.get("token") === undefined) &&
                <MessagePanel message="REQUEST_DENIED" instantRedirect={false} />
            }
            <ToastRootContainer />
        </I18nextProvider>
    )
}
