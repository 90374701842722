import React, { useEffect, useState } from 'react';
import { useSearchParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { Container } from './style';
import { MESSAGE_TYPE } from '../../../../utils/constants'
import { APIService } from '../../../../api/service';
import { BottomNavBar } from "../_misc/BottomNavBar";
import { MessagePanel } from "../../../_misc/MessagePanel";
import { CancelModal } from "../../../_misc/CancelModal";
import { IEFormSFSBaseContainer } from "../IEBaseContainer";
import { HouseholdModal } from "../../SFS/_misc/HouseholdModal";
import { GlobalStyle } from '../_misc/Common/style';
import { SummaryCalculator } from '../utils/summaryCalculator';

export const HomeSFS = (props) => {
    const { t } = useTranslation();
    const [searchParams] = useSearchParams();
    const [forwardTrigger, setOnForwardButtonClicked] = useState(0);
    const [backwardTrigger, setOnBackwardButtonClicked] = useState(0);
    const [mockDataTrigger, setOnMockDataClicked] = useState(0);
    const [showWarningModal, setShowWarningModal] = useState(false);
    const [journeyCanceled, setOnJourneyCanceled] = useState(false);
    const [journeyFinished, setOnJourneyFinished] = useState(false);
    const [tabsInfo, setTabsInfo] = useState({ currentTab: 0, tabsLength: 999, dataToSave: null });
    const [summary, setSummary] = useState({});
    const [householdSize, setHouseholdSize] = useState(props.formRecord.formData.householdDetails);
    const [showHouseholdModal, setShowHouseholdModal] = useState(false);

    const pathname = window.location.pathname.replace('/', '');

    useEffect(() => {
        SummaryCalculator.recalculate(props.formRecord.formData);
        setSummary(props.formRecord.formData.summary);
    }, [])

    const handleForwardClick = () => {
        handleStateChanges(tabsInfo);

        setOnForwardButtonClicked((forwardTrigger) => ++forwardTrigger);
    };

    const handleOnBackward = () => {
        if (tabsInfo.currentTab === tabsInfo.tabsLength)
            return;

        if (tabsInfo.currentTab === 0) {
            setShowWarningModal(true);
            return;
        }

        setOnBackwardButtonClicked((backwardTrigger) => ++backwardTrigger);
    };

    const hangleOnGenerateMockClick = () => {
        setOnMockDataClicked((mockDataTrigger) => ++mockDataTrigger);
    }

    const onHouseholdButtonClicked = () => {
        setShowHouseholdModal(true);
    }

    const handleOnCancelHouseholdModalClick = () => {
        setShowHouseholdModal(false);
    }

    const handleOnSaveHouseholdClick = (value) => {
        setHouseholdSize(value);
        props.formRecord.formData.householdDetails = value;

        setShowHouseholdModal(false);
    }

    const handleStateChanges = (tabsInfo) => {
        setTabsInfo(tabsInfo);
        

        if (tabsInfo.currentTab < 0)
            setOnJourneyCanceled(true);
        if (tabsInfo.currentTab === tabsInfo.tabsLength) {
            if (tabsInfo.dataToSave) {
                tabsInfo.dataToSave.formData.householdDetails = householdSize;

                APIService.finishApplication({ formRecord   : tabsInfo.dataToSave, UId: pathname, Token: searchParams.get("token") })
                    .then(() => {
                        setOnJourneyFinished(true);
                    })
                    .catch(error => {
                        toast.error(`${t('NOTIFICATIONS.UNEXPECTED_ERROR')}: ${error.message}`, { progress: undefined })
                        console.log(error);
                    });
            }
        }
    };

    function handleOnLeaveClick() {
        APIService.cancleApplication({ UId: pathname, Token: searchParams.get("token") })
            .then(() => {
                setShowWarningModal(false);
                setOnJourneyCanceled(true);
            })
            .catch(error => {
                toast.error(`${t('NOTIFICATIONS.UNEXPECTED_ERROR')}: ${error.message}`, { progress: undefined })
                console.log(error);
            });
    };

    function handleOnStayClick() {
        setShowWarningModal(false);
    };

    return (
        <div>
            {(!journeyCanceled && !journeyFinished) &&
                <Container className="dx-theme-material-typography">
                    <GlobalStyle/>
                    <IEFormSFSBaseContainer {...props}
                        onForwardButtonClicked={forwardTrigger}
                        onBackwardButtonClicked={backwardTrigger}
                        onMockDataTrigger={mockDataTrigger}
                        handleSummaryChanged={setSummary}
                        handleStateChanges={handleStateChanges}
                        onHouseholdButtonClicked={onHouseholdButtonClicked}
                    />

                    <BottomNavBar
                        tabsInfo={tabsInfo}
                        summary={summary}
                        householdSize={householdSize}
                        onForwardClick={handleForwardClick}
                        onGenerateMockClick={hangleOnGenerateMockClick}
                        onBackwardClick={handleOnBackward}
                        onHouseholdButtonClicked={onHouseholdButtonClicked}
                    />
                    
                    <CancelModal
                        showWarningModal={showWarningModal}
                        handleOnLeaveClick={handleOnLeaveClick}
                        handleOnStayClick={handleOnStayClick}
                    />

                    <HouseholdModal
                        showModal={showHouseholdModal}
                        currentValue={props.formRecord.formData.householdDetails}
                        handleOnCancelClick={handleOnCancelHouseholdModalClick}
                        handleOnSaveClick={handleOnSaveHouseholdClick}
                    />

                </Container>
            }
            {journeyCanceled && <MessagePanel message="JOURNEY_CANCELED" instantRedirect />}
            {journeyFinished && <MessagePanel {...props} message="JOURNEY_COMPLETED" type={MESSAGE_TYPE.SUCCESS} instantRedirect />}
        </div>
    );
}