import styled from 'styled-components';
import { breakpoints } from "../../../../utils/styledBreakpoints";

export const NumberInputContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-basis: 100%;
    flex-wrap: wrap;

    div.d-flex.flex-row {
        display: flex;
        flex-basis: 67%;
        flex-direction: row;
        gap: 0.7rem;
        ${breakpoints("flex-basis", "%", [{ 991: `100` }])};
    }

    label {
        flex-basis: 33%;
        font-size: 1rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        display: flex;
        align-items: center;
        ${breakpoints("flex-basis", "%", [{ 991: `100` }])};
    }

    .dx-dropdowneditor {
        width: 25%;
        ${breakpoints("width", "%", [{ 991: `70` }])};
    }

    .dx-texteditor.dx-editor-filled::after {
        border: none;
    }

    .dx-numberbox {
        width: 100%
    }

    .dx-numberbox input {
        text-align: right;
    }

    .note-label {
        font-size: 0.8rem;
    }
`;


export const NumberSelectContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    ${breakpoints("flex-basis", "%", [{ 991: `100` }])};

    div.d-flex.flex-row {
        display: flex;
        flex-direction: column;
        gap: 0.7rem;
        
    }

    label {
        font-size: 1rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-bottom: 0.5rem;
    }

    .dx-dropdowneditor {
         width: calc((100% - 3.5rem) / 3);
        ${breakpoints("width", "%", [{ 991: `100` }])};
    }

    .dx-texteditor.dx-editor-filled::after {
        border: none;
    }

    .dx-numberbox {
        width: 100%
    }

    .dx-numberbox input {
        text-align: right;
    }

    .note-label {
        font-size: 0.8rem;
    }
`;

export const CheckBoxInputContainer = styled.div`
    display: flex;
    flex-basis: 100%;
`;

export const TextareaInputContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    order: 99;

    label {
        font-size: 1rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-bottom: 0.5rem;
    }
`;