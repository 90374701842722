import React from 'react';
import { Popup } from 'devextreme-react/popup';
import { Button } from 'devextreme-react/button';
import { useTranslation } from 'react-i18next';

export const CancelModal = (props) => {
    const { t } = useTranslation();

    const renderTitle = () => {
        return (<h6 className="mt-3 mb-3">{t("MODALS.CANCEL_APPLICATION.TITLE_TEXT")}</h6>)
    }

    return (
        <Popup
            visible={props.showWarningModal}
            dragEnabled={false}
            hideOnOutsideClick={false}
            showCloseButton={false}
            titleRender={renderTitle}
            position={{ my: 'center', at: 'center', collision: "fit" }}
            container="#root" width='auto' height='auto'>
            <div>
                <p className="w-100 text-center">{t("MODALS.CANCEL_APPLICATION.BODY_TEXT")}</p>
                <div className="d-flex flex-row justify-content-between mt-4">
                    <Button text={t('BUTTONS.STAY')} type="default" stylingMode="contained" onClick={props.handleOnStayClick} />
                    <Button text={t('BUTTONS.LEAVE')} type="normal" stylingMode="contained" onClick={props.handleOnLeaveClick} />
                </div>
            </div>
        </Popup>
    );
}