import React, { useState, useEffect, useRef } from 'react';
import update from 'immutability-helper';
import NumberBox from 'devextreme-react/number-box';
import { TextArea } from 'devextreme-react/text-area';
import { useTranslation } from 'react-i18next';
import { NumberInputContainer, TextareaInputContainer, FrequencyButton, NotesModalButton } from './style';
import { IE_FREQUENCY_TYPE } from '../../../../utils/constants';
import { FrequencyModal } from '../_misc/FrequencyModal';
import { NotesModal } from '../_misc/NotesModal';
import { Tooltip } from 'devextreme-react/tooltip';

export const IEFieldContainer = (props) => {
    const { t, i18n } = useTranslation();
    const [fieldData, setFieldData] = useState(props);
    const [frequencyTypesMapped, setFrequencyTypesMapped] = useState([]);
    const [showFrequencyModal, setShowFrequencyModal] = useState(false);
    const [showNotesModal, setShowNotesModal] = useState(false);
    const [frequencyColors, setFrequencyColors] = useState({});
    const [showTooltip, setShowTooltip] = useState(false);
    const [tooltipShown, setTooltipShown] = useState(false);
    const [lastStableValue, setLastStableValue] = useState(fieldData.value);
    const tooltipRef = useRef(null);

    useEffect(() => {
        props.handleValueChange(fieldData);
    }, [fieldData]);

    useEffect(() => {
        setFrequencyTypesMapped(IE_FREQUENCY_TYPE.map(x => { return { Id: x.Id, Name: t(x.Name) } }));
    }, [i18n.language]);

    useEffect(() => {
        if (props.isDisabled) {
            setFieldData(update(fieldData, { note: { $set: null }, isDisabled: { $set: true }, isTouched: { $set: false }, frequency: { $set: 'Monthly' }, value: { $set: null } }))
            return;
        }

        setFieldData(props);

        if (props.frequency)
            setFrequencyColors(IE_FREQUENCY_TYPE.find(x => x.Id === props.frequency));

    }, [props]);

    useEffect(() => {
        const handleScroll = () => {
            if (tooltipRef && tooltipRef.current && tooltipRef.current.instance)
                tooltipRef.current.instance.repaint();
        };

        if (showTooltip === true) {
            window.addEventListener('scrollend', handleScroll, true);
            handleScroll();
        }
        else
            window.removeEventListener('scrollend', handleScroll, true);
       
    }, [showTooltip]);

    const getFieldElement = () => {
        switch (fieldData.elementType) {
            case 'number':
                return getNumberInputElement();
            case 'string':
                return getTextareaInputElement();
            case 'object':
            default:
                return null;
        }
    }

    const getTextareaInputElement = () => {
        return (
            <TextareaInputContainer>
                <label className="note-label">{t('GENERAL.ADDITIONAL_NOTES')}</label>
                <TextArea
                    defaultValue={(fieldData.value !== undefined && fieldData.value !== null) ? fieldData.value : null}
                    value={fieldData.value}
                    onValueChanged={handleValueChange}
                    disabled={fieldData.isDisabled || fieldData.isLocked}
                    isValid={!fieldData.required || (fieldData.required && fieldData.value !== null)}
                    stylingMode="outlined"
                    autoResizeEnabled={true}
                    minHeight={'3.5rem'}
                    placeholder={t('PLACEHOLDERS.ENTER_NOTE')} />
            </TextareaInputContainer>);
    }

    const handleValueChange = (e) => {
        // On iOS, avoid toggling between two values with undefined events
        if (e.event === undefined) {
            if (e.previousValue === lastStableValue) {
                setFieldData(update(fieldData, { value: { $set: e.previousValue }, isTouched: { $set: true } }));
                return;
            } else {
                return;
            }
        }
        else {
            setFieldData(update(fieldData, { value: { $set: e.value }, isTouched: { $set: true } }));
            setLastStableValue(e.value);
        }
    };

    const setTouched = () => setFieldData(update(fieldData, { isTouched: { $set: true } }));

    const frequencyModalClick = () => {
        setShowFrequencyModal(true);
    }

    const frequencyNotesClick = () => {
        setShowNotesModal(true);
    }

    const handleOnCancelFrequencyModalClick = () => {
        setShowFrequencyModal(false);
    }

    const handleOnCancelNotesModalClick = () => {
        setShowNotesModal(false);
    }

    const handleOnSaveFrequencyClick = (selectedFrequency) => {
        setFieldData(update(fieldData, { frequency: { $set: selectedFrequency } }));
        setFrequencyColors(IE_FREQUENCY_TYPE.find(x => x.Id === fieldData.frequency));
        setShowFrequencyModal(false);
    }

    const handleOnSaveNotesClick = (value) => {
        setFieldData(update(fieldData, { note: { $set: value }, isTouched: { $set: true } }))
        setShowNotesModal(false);
    }

    const validateInput = () => {
        if (!fieldData.isTouched || fieldData.isDisabled || fieldData.isLocked)
            return true;

        if (fieldData.title.toLowerCase().indexOf('other') === -1 && fieldData.value >= 0)
            return true;

        if (fieldData.title.toLowerCase().indexOf('other') > -1 && (fieldData.value === 0 || (fieldData.value > 0 && (fieldData.note !== undefined && fieldData.note !== null && fieldData.note !== '')))) {
            return true;
        }
        else {
            if (!tooltipShown) {
                setShowTooltip(true);
                setTooltipShown(true);
                setTimeout(() => { setShowTooltip(false); }, 5000);
            }

            return false;
        }
    } 

    const getNumberInputElement = () => {
        return (
            <NumberInputContainer>
                <div className="header">
                    <label>{t(`LABELS.${fieldData.title.toUpperCase()}`)}</label>
                    <FrequencyButton onClick={frequencyModalClick} $color={frequencyColors.Color} $textcolor={frequencyColors.TextColor}>
                        <div className="freq">{fieldData.frequency}</div>
                    </FrequencyButton>
                </div>
                <div className="d-flex flex-row">
                    <NumberBox
                        id={fieldData.title}
                        key={fieldData.title}
                        defaultValue={fieldData.value}
                        value={fieldData.value}
                        placeholder={t('PLACEHOLDERS.ENTER_VALUE')}
                        format={fieldData.isInteger ? '' : `#,##0.00`}
                        step={0}
                        min={0}
                        mode="number"
                        disabled={fieldData.isDisabled || fieldData.isLocked}
                        validationMessageMode="auto"
                        stylingMode="outlined"
                        onFocusIn={setTouched}
                        isValid={validateInput()}
                        onValueChanged={handleValueChange}>
                    </NumberBox>
                    <NotesModalButton id={fieldData.title + 'noteButton'} $hasNote={Boolean(fieldData.note)} $noteRequired={fieldData.title.toLowerCase().indexOf('other') > -1 && validateInput() === false} onClick={frequencyNotesClick} />
                </div>

                {showFrequencyModal && (
                    <FrequencyModal
                        showModal={showFrequencyModal}
                        title={`LABELS.${fieldData.title.toUpperCase()}`}
                        dataSource={frequencyTypesMapped}
                        currentValue={fieldData.frequency}
                        handleOnCancelClick={handleOnCancelFrequencyModalClick}
                        handleOnSaveClick={handleOnSaveFrequencyClick}
                    />
                )}

                {showNotesModal && (
                    <NotesModal
                        showModal={showNotesModal}
                        title={`LABELS.${fieldData.title.toUpperCase()}`}
                        currentValue={fieldData.note}
                        handleOnCancelClick={handleOnCancelNotesModalClick}
                        handleOnSaveClick={handleOnSaveNotesClick}
                    />
                )}

                {showTooltip && (
                    <Tooltip
                        ref={tooltipRef}
                        target={'#' + fieldData.title}
                        hideOnOutsideClick={false}
                        visible={true}>
                            {t('MESSAGE_LABELS.NOTE_REQUIRED')}
                    </Tooltip>) } 
            </NumberInputContainer>
        );
    }

    return getFieldElement();
}
