import React, { useState, useEffect } from 'react';
import update from 'immutability-helper';
import CheckBox from 'devextreme-react/check-box';
import NumberBox from 'devextreme-react/number-box';
import SelectBox from 'devextreme-react/select-box';
import { TextBox } from 'devextreme-react/text-box';
import { TextArea } from 'devextreme-react/text-area';
import { useTranslation } from 'react-i18next';
import { NumberInputContainer, CheckBoxInputContainer, NumberSelectContainer, TextareaInputContainer } from './style';
import { IE_FREQUENCY_TYPE } from '../../../../utils/constants';

export const IEFieldContainer = (props) => {
    const { t, i18n } = useTranslation();
    const [fieldData, setFieldData] = useState(props);
    const [frequencyTypesMapped, setFrequencyTypesMapped] = useState([]);
    const [householdSizes, setHouseholdSizes] = useState([]);
    const [lastStableValue, setLastStableValue] = useState(fieldData.value);

    useEffect(() => {
        props.handleValueChange(fieldData);
        setHouseholdSizes([...Array(16).keys()]);
    }, [fieldData]);

    useEffect(() => {
        setFrequencyTypesMapped(IE_FREQUENCY_TYPE.map(x => { return { Id: x.Id, Name: t(x.Name) } }));
    }, [i18n.language]);

    useEffect(() => {
        if (props.isDisabled) {
            setFieldData(update(fieldData, { note: { $set: null }, isDisabled: { $set: true }, isTouched: { $set: false }, frequency: { $set: 'Monthly' }, value: { $set: null } }))
            return;
        }

        setFieldData(props);
    }, [props]);

    const getFieldElement = () => {
        switch (fieldData.elementType) {
            case 'boolean':
                return getBoolenaInputElement();
            case 'number':
                if (fieldData.isInteger)
                    return getSelectorInputElement()
                else
                    return getNumberInputElement();
            case 'string':
                return getTextareaInputElement();
            case 'object':
            default:
                return null;
        }
    }

    const getTextareaInputElement = () => {
        return (
            <TextareaInputContainer>
                <label className="note-label">{t('GENERAL.ADDITIONAL_NOTES')}</label>
                <TextArea
                    defaultValue={(fieldData.value !== undefined && fieldData.value !== null) ? fieldData.value : null}
                    value={fieldData.value}
                    onValueChanged={handleValueChange}
                    disabled={fieldData.isDisabled || fieldData.isLocked}
                    isValid={!fieldData.required || (fieldData.required && fieldData.value !== null)}
                    stylingMode="outlined"
                    autoResizeEnabled={true}
                    minHeight={'3.5rem'}
                    placeholder={t('PLACEHOLDERS.ENTER_NOTE')} />
            </TextareaInputContainer>);
    }
    
    const getSelectorInputElement = () => {
        if (fieldData.title === 'noAdults' && fieldData.value === 0)
            setFieldData(update(fieldData, { value: { $set: props.isJoint ? 2 : 1 }, isTouched: { $set: false } }))

        return (
            <NumberSelectContainer>
                <label>{t(`LABELS.${fieldData.title.toUpperCase()}`)}</label>
                <SelectBox
                    dataSource={householdSizes}
                    stylingMode="outlined"
                    disabled={fieldData.isDisabled || fieldData.isLocked}
                    isValid={fieldData.title === 'noAdults' ? fieldData.value >= 1 : fieldData.value !== null}
                    value={fieldData.value}
                    onValueChanged={handleValueChange} />
            </NumberSelectContainer>);
    }

    const getBoolenaInputElement = () => {
        return (
            <CheckBoxInputContainer>
                <CheckBox
                    key={fieldData.title}
                    enableThreeStateBehavior={false}
                    disabled={fieldData.isDisabled || fieldData.isLocked}
                    text={t(`LABELS.${fieldData.title.toUpperCase()}`)}
                    value={fieldData.value}
                    onValueChanged={handleValueChange} />
            </CheckBoxInputContainer>);
    }

    const validateInput = () => {
        if (!fieldData.isTouched || fieldData.isDisabled || fieldData.isLocked)
            return true;

        if ((fieldData.title.toLowerCase().indexOf('other') === -1 && fieldData.title.toLowerCase().indexOf('netgovernmentbenefits') === -1) && fieldData.value >= 0)
            return true;

        return (fieldData.title.toLowerCase().indexOf('other') > -1 || fieldData.title.toLowerCase().indexOf('netgovernmentbenefits') > -1)
            && (fieldData.value === 0 || (fieldData.value > 0 && (fieldData.note !== undefined && fieldData.note !== null && fieldData.note !== '')));
    } 

    const handleValueChange = (e) => {
        // On iOS, avoid toggling between two values with undefined events
        if (e.event === undefined) {
            if (e.previousValue === lastStableValue) {
                setFieldData(update(fieldData, { value: { $set: e.previousValue }, isTouched: { $set: true } }));
                return;
            } else {
                return;
            }
        }
        else {
            setFieldData(update(fieldData, { value: { $set: e.value }, isTouched: { $set: true } }));
            setLastStableValue(e.value);
        }
    };

    const handleFrequencyValueChanged = (e) => setFieldData(update(fieldData, { frequency: { $set: e.value } }));

    const handleNoteValueChanged = (e) => setFieldData(update(fieldData, { note: { $set: e.value }, isTouched: { $set: true } }));

    const setTouched = () => setFieldData(update(fieldData, { isTouched: { $set: true } }));

    const getNumberInputElement = () => {
        return (
            <NumberInputContainer>
                <label>{t(`LABELS.${fieldData.title.toUpperCase()}`)}</label>
                <div className="d-flex flex-row">
                    {fieldData.frequency &&
                        <SelectBox
                            dataSource={frequencyTypesMapped}
                            stylingMode="outlined"
                            valueExpr="Id"
                            disabled={fieldData.isDisabled || fieldData.isLocked}
                            displayExpr="Name"
                            isValid={validateInput()}
                            value={fieldData.frequency}
                            onValueChanged={handleFrequencyValueChanged} />
                    }
                    <NumberBox
                        key={fieldData.title}
                        defaultValue={fieldData.value}
                        inputMode="numeric"  // Try forcing numeric input mode for iOS
                        value={fieldData.value}
                        placeholder={t('PLACEHOLDERS.ENTER_VALUE')}
                        format={fieldData.isInteger ? '' : `CA$#,##0.00`} 
                        step={0}
                        min={0}
                        mode="number"
                        disabled={fieldData.isDisabled || fieldData.isLocked}
                        isValid={validateInput()}
                        validationMessageMode="auto"
                        stylingMode="outlined"
                        onFocusOut={setTouched}
                        onValueChanged={handleValueChange}>
                    </NumberBox>

                </div>

                {((fieldData.note || !validateInput()) && !fieldData.isInteger) &&
                    <div className="mt-2 w-100">
                        <label className="note-label">{t(props.isIncomeField ? 'GENERAL.ENTER_INCOME_NOTE' : 'GENERAL.ENTER_EXPENSES_NOTE')}</label>
                        <TextBox
                            defaultValue={(fieldData.note !== undefined && fieldData.note !== null) ? fieldData.note : null}
                            value={fieldData.note}
                            onValueChanged={handleNoteValueChanged}
                            disabled={fieldData.isDisabled || fieldData.isLocked}
                            stylingMode="outlined"
                            isValid={validateInput()}
                            placeholder={t('PLACEHOLDERS.ENTER_NOTE')} />
                    </div>
                }
            </NumberInputContainer>);
    }

    return getFieldElement();
}