import { chain, round, floor, multiply  } from 'mathjs'
import { FINANCIAL_FREQUENCIES } from '../../../../utils/constants'
import { FLEXIBLE_EXPENSES } from './constants';


export const SummaryCalculator = {
    recalculate: (data) => {
        for (var category in data) {
            if (typeof data[category] === 'object') {
                for (var subcategory in data[category]) {
                    if (data[category][subcategory]) {
                        if (typeof data[category][subcategory] === 'object' && category !== 'householdDetails') {
                            data[category][subcategory].total = calculateBasedOnFrequencyValues(data[category][subcategory].data);
                        }
                    }
                }
            }
        }

        if (!data.summary)
            data.summary = {
                totalIncome: 0,
                totalFixedOutgoings: 0,
                totalFlexibleOutgoings: 0,
                contribution: 0
            };

        data['summary'].totalIncome = calculateSummaryIncome(data);
        data['summary'].totalFixedOutgoings = calculateSummaryExpenses(data, false);
        data['summary'].totalFlexibleOutgoings = calculateSummaryExpenses(data, true);

        data['summary'].contribution = data['summary'].totalIncome - (data['summary'].totalFixedOutgoings + data['summary'].totalFlexibleOutgoings);
    }
};

const calculateBasedOnFrequencyValues = (data) => {
    const total = data.reduce((sum, x) => {
        return !x ? sum : +(sum + getMonthlyValue(x.amount, x.frequency)).toPrecision(15);
    }, 0);
    return Math.floor(total * 100) / 100;
}

const calculateSummaryIncome = (data) => {
    var result = 0;

    for (var subcategory in data['income']) {
        if (data['income'][subcategory]) {
            if (typeof data['income'][subcategory] === 'object') {
                result = result + data['income'][subcategory].total;
            }
        }
    }

    return result;
}

const calculateSummaryExpenses = (data, isFlexible) => {
    var result = 0;
    for (var subcategory in data['expenditure']) {
        if ((isFlexible && FLEXIBLE_EXPENSES.indexOf(subcategory) > -1) ||
             (!isFlexible && FLEXIBLE_EXPENSES.indexOf(subcategory) < 0)) {
            result += data['expenditure'][subcategory].total;
        }
    }

    return result;
}

const getMonthlyValue = (value, frequency) => {
    if (!value)
        value = 0;

    var convertedValue = null;

    switch (frequency) {
        case FINANCIAL_FREQUENCIES.WEEKLY:
            convertedValue = chain(value).multiply(52).divide(12).done();
            break;
        case FINANCIAL_FREQUENCIES.FORTNIGHTLY:
            convertedValue = chain(value).multiply(26).divide(12).done();
            break;
        case FINANCIAL_FREQUENCIES.FOURWEEKLY:
            convertedValue = chain(value).multiply(13).divide(12).done();
            break;
        case FINANCIAL_FREQUENCIES.MONTHLY:
            convertedValue = value;
            break;
        case FINANCIAL_FREQUENCIES.QUARTERLY:
            convertedValue = chain(value).divide(3).done();
            break;
        case FINANCIAL_FREQUENCIES.ANNUALLY:
            convertedValue = chain(value).divide(12).done();
            break;
        default:
            convertedValue = value;
    }

    return convertedValue;
}