import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpBackend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

const urlParams = new URLSearchParams(window.location.search);
const selectedLanguage = urlParams.get('lang');

i18n.use(initReactI18next)
    .use(HttpBackend)
    .use(LanguageDetector)
    .init({
        debug: false,
        lng: selectedLanguage || 'en-GB',
        fallbackLng: 'en-GB',
        ns: 'Form65',
        defaultNS: 'Form65',
        keySeparator: '.',
        interpolation: {
            escapeValue: false,
        },
        backend: {
            loadPath: 'i18n/{{ns}}/{{lng}}'
        }
    }, (err, t) => {
        if (err) return console.error(err)
    })

export default i18n;