export const IEFormDataService = {
    generateTabs: (formData) => generateTabsFromForm65(formData),
    generateCategories: (data, recordCreationDate, settings, isJoint) => generateCategoriesFromForm65(data, recordCreationDate, settings, isJoint)
};

const generateTabsFromForm65 = (formData) => {
    return Object.keys(formData)
        .filter((key, i) => key === 'income' || key === 'expenses')
        .map((key, i) => { return { id: i, dataObject: key } })
        .reverse();
}

const generateCategoriesFromForm65 = (categories, recordCreationDate, settings, isJoint) => {
    var result = Object.keys(categories)
        .map((key, i) => {
            if (categories[key] && categories[key].data && Array.isArray(categories[key].data)) {
                return {
                    id: i,
                    key: key,
                    total: categories[key].total,
                    items: categories[key].data.map(obj => ({
                        ...obj,
                        parent: key,
                        isTouched: obj.isTouched || false
                    }))
                };
            }
            return {
                id: i,
                key: key,
                objectPropertry: key,
                content: categories[key],
                type: key === 'note' ? typeof '' : typeof categories[key]
            };
        })
        .filter(x => x !== 'undefined' &&
            (isJoint === true ||
                (x.key !== 'jointBankruptDebtorIncome' && x.key !== 'nonDiscretionaryJointBankruptDebtor')))
        .sort((a, b) => {
            if (a.type === "object" && b.type !== "object") return 1;
            else if (a.type !== "object" && b.type === "object") return -1;
            else return 0;
        });

    //DBSENSE-8446
    if (settings && settings.FORM65_HIDE_FIELDS_AFTER_DATE && result.some(x => x.key === "nonRecoverableMedical")) {
        var _recordCreationDate = new Date(recordCreationDate);
        var _hideAfterDate = new Date(settings.FORM65_HIDE_FIELDS_AFTER_DATE);
        
        if (_recordCreationDate > _hideAfterDate)
            result = result.filter(x => x.key !== "nonRecoverableMedical");
    }

    return result;
}