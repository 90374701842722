import React from 'react';
import { Button } from 'devextreme-react/button';
import { useTranslation } from 'react-i18next';
import { BottomNavBarElement } from './style';

export const BottomNavBar = (props) => {
    const { t } = useTranslation();

    return (
        <BottomNavBarElement>
            <Button disabled={props.tabsInfo.currentTab === props.tabsInfo.tabsLength} text={props.tabsInfo.currentTab === 0 ? t('BUTTONS.CANCEL') : t('BUTTONS.BACK')} type="normal" stylingMode="contained" onClick={props.onBackwardClick} />
            {/*<Button text={"GENERATE MOCK DATA"} type="danger" stylingMode="contained" onClick={props.onGenerateMockClick} />*/}
            <Button disabled={props.tabsInfo.currentTab === props.tabsInfo.tabsLength} text={props.tabsInfo.currentTab < props.tabsInfo.tabsLength - 1 ? t('BUTTONS.NEXT') : t('BUTTONS.FINISH')} type="default" stylingMode="contained" onClick={props.onForwardClick} />
        </BottomNavBarElement>
    );
}