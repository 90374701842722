import styled from 'styled-components';

export const SpinnerElement = styled.div`
    height: ${props => props.$isInner ? '75vh' : '100vh'};
    width: ${props => props.$isInner ? '100%' : '100vw'};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2.8rem;
`;

export const IconSpinner = styled.div`
    stroke: var(--sidebar-color);
    height: 10em;
    width: 10em;
`;