import { FLEXIBLE_EXPENSES } from "./constants";

export const IEFormDataService = {
    generateTabs: (formData) => generateTabs(formData),
    generateCategories: (data, currentTab) => generateCategories(data, currentTab)
};

const generateTabs= (formData) => {
    return [
        {
            id: 0,
            dataObject: 'income',
            dataObjectMobile: 'income',
        },
        {
            id: 1,
            dataObject: 'fixed_expenditure',
            dataObjectMobile: 'fixed'
        },
        {
            id: 2,
            dataObject: 'flexible_expenditure',
            dataObjectMobile: 'flexible',
        }
    ];
}

const generateCategories = (categories, currentTab) => {
    var result = Object.keys(categories)
        .map((key, i) => {
            if (categories[key] && categories[key].data && Array.isArray(categories[key].data))
                return { id: i, key: key, total: categories[key].total, isFlexible: FLEXIBLE_EXPENSES.indexOf(key) > -1, items: categories[key].data.map(obj => ({ ...obj, parent: key, isTouched: obj.isTouched || false })) }
            return { id: i, key: key, objectPropertry: key, content: categories[key], type: key === 'note' ? typeof '' :  typeof categories[key] }
        })
        .filter(x => x !== 'undefined')
        .sort((a, b) => {
            if (a.type === "object" && b.type !== "object") return 1;
            else if (a.type !== "object" && b.type === "object")return -1;
            else return 0;
        });

    if (currentTab.indexOf('expenditure') === -1)
        return result;

    if (currentTab.indexOf('flexible') > -1)
        return result.filter(x => x.isFlexible);
    else
        return result.filter(x => !x.isFlexible);
}