import styled from 'styled-components';
import { breakpoints } from "../../../utils/styledBreakpoints";

export const LogoContainerElement = styled.div`
    width: auto;
    background: var(--sidebar-color);
`;

export const LogoElement = styled.div`
    display: block;
    width: 15rem;
    margin-top: 50px;
    text-align: center;
    ${breakpoints("width", "rem", [{ 1280: 10 }])};
    ${breakpoints("margin-top", "px", [{ 991: 20 }])};
    ${breakpoints("margin-left", "px", [{ 991: 40 }])};
    ${breakpoints("text-align", "", [{ 991: `left` }])};

    img {
        width: inherit;
        padding: 0 2rem;
        content: url(${props => props.$logoURL});
        max-width: 15rem;

        @media (max-width: 991px) {
            max-height: 2.7rem;
            padding: 0;
            content: url(${props => props.$mobileLogoURL});
        }
    }
`;