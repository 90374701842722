import styled from 'styled-components';
import { breakpoints } from "../../../utils/styledBreakpoints";

export const MessagePanelElement = styled.div`
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2.8rem;

    ${breakpoints("gap", "rem", [{ 991: 1.7 }])};
`;

export const IconError = styled.div`
    .circle1 {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #FEDDD8;
        height: 6rem;
        width: 6rem;
        border-radius: 50px;
    }

    .circle2 {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #E02B2B;
        height: 4rem;
        width: 4rem;
        border-radius: 50px;
    }

    .circle3 {
        background-position: center;
        height: 100%;
        width: 100%;
        background-repeat: no-repeat;
        background-size: cover;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'%3E%3Cpath d='M9.99996 18.3333C14.6023 18.3333 18.3333 14.6023 18.3333 9.99996C18.3333 5.39759 14.6023 1.66663 9.99996 1.66663C5.39759 1.66663 1.66663 5.39759 1.66663 9.99996C1.66663 14.6023 5.39759 18.3333 9.99996 18.3333Z' fill='%23E02B2B' stroke='%23E02B2B' stroke-width='1.5'/%3E%3Cpath d='M10 6.66663V10.8333' stroke='white' stroke-width='1.5' stroke-linecap='round'/%3E%3Cpath d='M10 13.3334H10.0083' stroke='white' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
   }
`;

export const IconSuccess = styled.div`
    .circle1 {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #1BA87E40;
        height: 6rem;
        width: 6rem;
        border-radius: 50px;
    }

    .circle2 {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #1BA87E;
        height: 4rem;
        width: 4rem;
        border-radius: 50px;
    }

    .circle3 {
        background-position: center;
        height: 100%;
        width: 100%;
        background-repeat: no-repeat;
        background-size: 65%;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3E%3Cpath d='M19 7L10 16L5 11' stroke='%23FFFFFF' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");   }
`;

export const Message = styled.div`
    font-family: Poppins;
    font-size: 2rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    ${breakpoints("font-size", "rem", [{ 991: 1 }])};
    ${breakpoints("text-align", "", [{ 991: `center` }])};
`;
