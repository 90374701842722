import styled from 'styled-components';
import { breakpoints } from "../../../../../utils/styledBreakpoints";

export const ButtonsContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: end;
    margin-top: 1.5rem;
    gap: 1.14rem;

    ${breakpoints("justify-content", "", [{ 991: `space-between` }])};
`;