import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from "react-router-dom";
import { MessagePanelElement, IconError, IconSuccess, Message } from './style';
import { MESSAGE_TYPE } from '../../../utils/constants'

export const MessagePanel = (props) => {
    const { t } = useTranslation();
    const [countdown, setCountdown] = useState(5);
    const [searchParams] = useSearchParams();
    const cancelUrl = searchParams.get("cancelUrl");

    useEffect(() => {
        if (cancelUrl || props.callbackUrl) {
            if (props.instantRedirect) {
                redirect();
                return;
            } 
           
            const timeId = setTimeout(redirect, 5000);
            return () => { clearTimeout(timeId); }
        }
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            setCountdown((prevCounter) => prevCounter - 1);
        }, 1000);

        return () => clearInterval(interval);
    }, []);

    const redirect = () => {
        const currentUrl = window.location.href;
        const urlObj = new URL(currentUrl);
        const params = new URLSearchParams(urlObj.search);
        const cancelUrl = params.get('cancelUrl');
        const fullCancelUrl = `${cancelUrl}${urlObj.hash}`;

        window.location.href = (props.type && props.type === MESSAGE_TYPE.SUCCESS) ? props.callbackUrl : fullCancelUrl;
    }

    return (
        <div>
            {(props.instantRedirect === false) &&
                <MessagePanelElement className="dx-theme-material-typography">
                    {(!props.type || props.type === MESSAGE_TYPE.ERROR) &&
                        <IconError>
                            <div className="circle1">
                                <div className="circle2">
                                    <div className="circle3"></div>
                                </div>
                            </div>
                        </IconError>
                    }

                    {(props.type && props.type === MESSAGE_TYPE.SUCCESS) &&
                        <IconSuccess>
                            <div className="circle1">
                                <div className="circle2">
                                    <div className="circle3"></div>
                                </div>
                            </div>
                        </IconSuccess>
                    }

                    <Message>{t(`MESSAGE_LABELS.${props.message}`)}</Message>

                    {cancelUrl && <Message> {t('MESSAGE_LABELS.REDIRECT_COUNTDOWN', { countdown: countdown })}</Message>}
                </MessagePanelElement>
            }
        </div>
    );
}
