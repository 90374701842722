export const TriggerFiguresService = {
    hasTriggerFiguresConfigured: (key, triggerFigures) => {
        return Object.keys(triggerFigures).some(x => x.toLowerCase() === key.toLowerCase());
    },
    getDependantsUnder16: (dependantsInHousehold) => getUnder16(dependantsInHousehold).length,
    getDependantsUnder18: (dependantsInHousehold) => getUnder18(dependantsInHousehold).length,
    getOtherDependants: (dependantsInHousehold) => getOther(dependantsInHousehold).length,
    calculateTriggerFigures: (key, triggerFigures, householdData) => {
        var guidelines = triggerFigures[Object.keys(triggerFigures).find(x => x.toLowerCase() === key.toLowerCase())];

        var rez = guidelines.firstAdultAmount
            + (getGuidelineFigures(getOther(householdData.dependantsInHousehold), guidelines.additionalAdultPerHeadAmount) + ((householdData.adultsInHousehold - 1) * guidelines.additionalAdultPerHeadAmount))
            + (getGuidelineFigures(getUnder16(householdData.dependantsInHousehold), guidelines.childrenUnder16Amount))
            + (getGuidelineFigures(getUnder18(householdData.dependantsInHousehold), guidelines.childrenUnder18Amount));

        return rez;
    },
};

const getUnder16 = (dependantsInHousehold) => dependantsInHousehold.filter(dependant => calculateAge(dependant.dateOfBirth) <= 16);
const getUnder18 = (dependantsInHousehold) => dependantsInHousehold.filter(dependant => calculateAge(dependant.dateOfBirth) > 16 && calculateAge(dependant.dateOfBirth) <= 18);
const getOther = (dependantsInHousehold) => dependantsInHousehold.filter(dependant => calculateAge(dependant.dateOfBirth) > 18); 

const getGuidelineFigures = (dependents, triggerFigure) => dependents.map(x => x.timeSpent * triggerFigure).reduce((partialSum, a) => partialSum + a, 0)

const calculateAge = (dateOfBirth) => {
    const today = new Date();
    const birthDate = new Date(dateOfBirth);
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();
    if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }

    return age;
};