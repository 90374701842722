import React, { useState, useEffect } from 'react';
import { Popup } from 'devextreme-react/popup';
import { Button } from 'devextreme-react/button';
import { useTranslation } from 'react-i18next';
import RadioGroup from 'devextreme-react/radio-group';
import { ButtonsContainer } from './style';

export const FrequencyModal = (props) => {
    const { t } = useTranslation();
    const [radioValue, setRadioValue] = useState(0);

    useEffect(() => {
        setRadioValue(props.currentValue)
    }, [props]);

    const renderTitle = () => {
        return (<h6 className="mb-3">{t(props.title)}</h6>)
    }

    const handleChangeSelection = (e) => {
        setRadioValue(e.value);
    }

    const handleSaveClick = () => {
        props.handleOnSaveClick(radioValue)
    }

    return (
        <Popup
            visible={props.showModal}
            dragEnabled={false}
            hideOnOutsideClick={false}
            showCloseButton={false}
            titleRender={renderTitle}
            position={{ my: 'center', at: 'center', collision: "fit" }}
            container="#root" height='auto'>
            <div>
                <RadioGroup
                    items={props.dataSource}
                    value={radioValue}
                    valueExpr="Id"
                    displayExpr="Name"
                    onValueChanged={handleChangeSelection}
                />
                <ButtonsContainer>
                    <Button text={t('BUTTONS.CANCEL')} type="normal" stylingMode="outlined" onClick={props.handleOnCancelClick} />
                    <Button className="mr-3" text={t('BUTTONS.SAVE')} type="default" stylingMode="contained" onClick={handleSaveClick} />
                </ButtonsContainer>
            </div>
        </Popup>
    );
}