import React, { useState, useEffect } from 'react';
import DateBox from 'devextreme-react/date-box';
import SelectBox from 'devextreme-react/select-box';
import NumberBox from 'devextreme-react/number-box';
import moment from 'moment';
import { Popup } from 'devextreme-react/popup';
import { Button } from 'devextreme-react/button';
import { useTranslation } from 'react-i18next';
import { HouseholdDependantsContainer, DependantsContainer } from './style';
import { toast } from 'react-toastify';

const percentageOptions = [
    { label: '0%', value: 0.0 },
    { label: '25%', value: 0.25 },
    { label: '50%', value: 0.5 },
    { label: '75%', value: 0.75 },
    { label: '100%', value: 1.0 },
];

export const HouseholdModal = (props) => {
    const { t } = useTranslation();
    const [householdDetails, setHouseholdDetails] = useState([]);
    const [initialHouseholdDetails, setInitialHouseholdDetails] = useState([]);
    const minDate = new Date();
    minDate.setFullYear(minDate.getFullYear() - 110);
    const maxDate = new Date();

    useEffect(() => {
        if (props.currentValue) {
            setInitialHouseholdDetails(props.currentValue);
            setHouseholdDetails(props.currentValue);
        }
    }, [props.currentValue]);

    const renderTitle = () => {
        return (<h6 className="mb-3">{t('MODALS.HOUSEHOLD.TITLE')}</h6>);
    };

    const handleSaveClick = () => {
        if (householdDetails.adultsInHousehold < 1) {
            toast.warn(t('NOTIFICATIONS.AT_LEAST_ONE_ADULT_IN_HOUSEHOLD'), { progress: undefined });
            return;
        }

        if (householdDetails.dependantsInHousehold.some(x => !x.dateOfBirth || !x.timeSpent)) {
            toast.warn(t('NOTIFICATIONS.FILL_REQUIRED_FIELDS'), { progress: undefined });
            return;
        }
        setInitialHouseholdDetails(householdDetails);
        props.handleOnSaveClick(householdDetails);
    };

    const handleCancelClick = () => {
        setHouseholdDetails(initialHouseholdDetails);
        props.handleOnCancelClick();
    }

    const addDependant = () => {
        setHouseholdDetails(prevState => ({
            ...prevState,
            dependantsInHousehold: [...prevState.dependantsInHousehold, { dateOfBirth: null, timeSpent: null }]
        }));
    };

    const updateDependant = (index, field, value) => {
        if (field === 'dateOfBirth') {
            const momentDate = moment(value).startOf('day');
            value = momentDate.format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
        }
       
        setHouseholdDetails(prevState => ({
            ...prevState,
            dependantsInHousehold: prevState.dependantsInHousehold.map((dep, i) =>
                i === index ? { ...dep, [field]: value } : dep
            )
        }));
    };

    const removeDependant = (index) => {
        setHouseholdDetails(prevState => ({
            ...prevState,
            dependantsInHousehold: prevState.dependantsInHousehold.filter((_, i) => i !== index)
        }));
    };

    const getDepenants = () => {
        return householdDetails.dependantsInHousehold.map((dep, index) => (
            <div key={index} className="dependant-row">
                <div className="d-flex flex-column item-1">
                    <label>{t('GENERAL.DATE_OF_BIRTH')}</label>
                    <DateBox
                        key={`dateOfBirth-${index}`}
                        value={dep.dateOfBirth}
                        type="date"
                        displayFormat="shortdate"
                        onValueChanged={(e) => updateDependant(index, 'dateOfBirth', e.value)}
                        stylingMode="outlined"
                        pickerType='date'
                        acceptCustomValue={false}
                        openOnFieldClick={true}
                        placeholder='DD/MM/YYYY'
                        min={minDate}
                        max={maxDate}
                    />
                </div>
                <div className="d-flex flex-column item-2">
                    <label>{t('GENERAL.TIME_SPENT')}</label>
                    <SelectBox
                        key={`timeSpent-${index}`}
                        items={percentageOptions}
                        displayExpr="label"
                        valueExpr="value"
                        value={dep.timeSpent}
                        onValueChanged={(e) => updateDependant(index, 'timeSpent', e.value)}
                        stylingMode="outlined"
                    />
                </div>
                <div className="d-flex flex-column item-3">
                    <label>&nbsp;</label>
                    <Button text={t('BUTTONS.REMOVE')} type="normal" stylingMode="outlined" onClick={() => removeDependant(index)} />
                </div>
            </div>
        ))
    }

    const renderDependantsList = () => {
        if (!householdDetails.dependantsInHousehold || !householdDetails.dependantsInHousehold.length)
            return <></>;
        return (
            <DependantsContainer>
                {getDepenants()}
            </DependantsContainer>
        );
    };

    return (
        <Popup
            visible={props.showModal}
            dragEnabled={false}
            hideOnOutsideClick={false}
            showCloseButton={false}
            titleRender={renderTitle}
            position={{ my: 'center', at: 'center', collision: "fit" }}
            className="custom-popup-class"
            container="#root" height='auto'>
            <div>
                <HouseholdDependantsContainer>
                    <div className="adult-input">
                        <label>{t('MODALS.HOUSEHOLD.ADULTS_IN_HOUSEHOLD')}</label>
                        <NumberBox
                            defaultValue={0}
                            value={householdDetails.adultsInHousehold}
                            placeholder={t('MODALS.HOUSEHOLD.ENTER_ADULTS_IN_HOUSEHOLD')}
                            format={`#`}
                            step={1}
                            min={1}
                            max={100}
                            mode="number"
                            stylingMode="outlined"
                            validationMessageMode="auto"
                            onValueChanged={(e) => setHouseholdDetails(prevState => ({
                                ...prevState,
                                adultsInHousehold: e.value
                            }))}
                        />
                    </div>
                    <hr />
                    <label>{t('MODALS.HOUSEHOLD.DEPENDENTS')}</label>
                    <p>{t('MODALS.HOUSEHOLD.DEPENDENTS_INFO')}</p>
                    {renderDependantsList()}
                </HouseholdDependantsContainer>
                <div className="d-flex flex-row justify-content-between gap-standard mt-4">
                    <Button text={t('BUTTONS.ADD')} type="default" stylingMode="contained" onClick={addDependant} />

                    <div className="d-flex flex-row justify-content-between gap-standard">
                        <Button text={t('BUTTONS.CANCEL')} type="normal" stylingMode="outlined" onClick={handleCancelClick} />
                        <Button className="mr-3" text={t('BUTTONS.SAVE')} type="default" stylingMode="contained" onClick={handleSaveClick} />
                    </div>
                </div>
            </div>
        </Popup>
    );
};
