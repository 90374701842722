import React from 'react';
import { SummaryContainerElement } from './style';

export const IESummaryContainer = (props) => {
    const formatValue = (value) => {
        return new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'CAD',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        }).format(value ? value : 0);
    }

    return (
        <SummaryContainerElement $isBalance={props.balance} $isPositive={props.value > 0}>
            <label>{props.title}</label>
            <label className="summary-value">{formatValue(props.value)}</label>
        </SummaryContainerElement>
    );
}