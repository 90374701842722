import React, { useState } from 'react';
import { useSearchParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { Container } from './style';
import { MESSAGE_TYPE } from '../../../../utils/constants'
import { APIService } from '../../../../api/service';
import { BottomNavBar } from "../_misc/BottomNavBar";
import { LogoContainer } from "../../../_misc/LogoContainer";
import { MessagePanel } from "../../../_misc/MessagePanel";
import { CancelModal } from "../../../_misc/CancelModal";
import { IEForm65BaseContainer } from "../IEBaseContainer";

export const HomeForm65 = (props) => {
    const { t } = useTranslation();
    const [searchParams] = useSearchParams();
    const [forwardTrigger, setOnForwardButtonClicked] = useState(0);
    const [backwardTrigger, setOnBackwardButtonClicked] = useState(0);
    const [mockDataTrigger, setOnMockDataClicked] = useState(0);
    const [showWarningModal, setShowWarningModal] = useState(false);
    const [journeyCanceled, setOnJourneyCanceled] = useState(false);
    const [journeyFinished, setOnJourneyFinished] = useState(false);
    const [tabsInfo, setTabsInfo] = useState({ currentTab: 0, tabsLength: 999, dataToSave: null });
    const pathname = window.location.pathname.replace('/', '');

    const handleForwardClick = () => {
        setOnForwardButtonClicked((forwardTrigger) => ++forwardTrigger);
    };

    const handleOnBackward = () => {
        if (tabsInfo.currentTab === tabsInfo.tabsLength)
            return;

        if (tabsInfo.currentTab === 0) {
            setShowWarningModal(true);
            return;
        }

        setOnBackwardButtonClicked((backwardTrigger) => ++backwardTrigger);
    };

    const hangleOnGenerateMockClick = () => {
        setOnMockDataClicked((mockDataTrigger) => ++mockDataTrigger);
    }

    const handleStateChanges = (tabsInfo) => {
        setTabsInfo(tabsInfo);

        if (tabsInfo.currentTab < 0)
            setOnJourneyCanceled(true);
        if (tabsInfo.currentTab === tabsInfo.tabsLength) {
            if (tabsInfo.dataToSave) {
                APIService.finishApplication({ formRecord: tabsInfo.dataToSave, UId: pathname, Token: searchParams.get("token") })
                    .then(() => {
                        setOnJourneyFinished(true);
                    })
                    .catch(error => {
                        toast.error(`${t('NOTIFICATIONS.UNEXPECTED_ERROR')}: ${error.message}`, { progress: undefined })
                        console.log(error);
                    });
            }
        }
    };

    function handleOnLeaveClick() {
        APIService.cancleApplication({ UId: pathname, Token: searchParams.get("token") })
            .then(() => {
                setShowWarningModal(false);
                setOnJourneyCanceled(true);
            })
            .catch(error => {
                toast.error(`${t('NOTIFICATIONS.UNEXPECTED_ERROR')}: ${error.message}`, { progress: undefined })
                console.log(error);
            });
    };

    function handleOnStayClick() {
        setShowWarningModal(false);
    };

    return (
        <div>
            {(!journeyCanceled && !journeyFinished) &&
                <Container className="dx-theme-material-typography">
                    <LogoContainer logoURL={props.logoURL} mobileLogoURL={props.mobileLogoURL} />

                    <IEForm65BaseContainer {...props}
                        onForwardButtonClicked={forwardTrigger}
                        onBackwardButtonClicked={backwardTrigger}
                        onMockDataTrigger={mockDataTrigger}
                        handleStateChanges={handleStateChanges} />
                    
                    <BottomNavBar
                        tabsInfo={tabsInfo}
                        onForwardClick={handleForwardClick}
                        onGenerateMockClick={hangleOnGenerateMockClick}
                        onBackwardClick={handleOnBackward}
                    />

                    <CancelModal
                        showWarningModal={showWarningModal}
                        handleOnLeaveClick={handleOnLeaveClick}
                        handleOnStayClick={handleOnStayClick} />

                </Container>
            }
            {journeyCanceled && <MessagePanel message="JOURNEY_CANCELED" instantRedirect />}
            {journeyFinished && <MessagePanel {...props} message="JOURNEY_COMPLETED" type={MESSAGE_TYPE.SUCCESS} instantRedirect />}
        </div>
    );
}